export const assemblyHistory = [
    {
        id: 1,
        date: "2024-11-20",
        piece: "Manches",
        operator: "Jean Dupont",
        quantity: 50,
    },
    {
        id: 2,
        date: "2024-11-18",
        piece: "Poches",
        operator: "Marie Curie",
        quantity: 30,
    },
    {
        id: 3,
        date: "2024-11-19",
        piece: "Cols",
        operator: "Paul Martin",
        quantity: 40,
    },
    {
        id: 4,
        date: "2024-11-21",
        piece: "Torse",
        operator: "Alice Doe",
        quantity: 25,
    },
    {
        id: 5,
        date: "2024-11-22",
        piece: "Bas de pantalon",
        operator: "John Smith",
        quantity: 60,
    },
    {
        id: 6,
        date: "2024-11-23",
        piece: "Poignets",
        operator: "Paul Martin",
        quantity: 35,
    },
];
