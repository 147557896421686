import React, { useState } from "react";
import { packagingData } from "../services/packagingData";

const PackagingList = () => {
    const [filter, setFilter] = useState("");
    const [data, setData] = useState(packagingData);

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const handleCreateBox = () => {
        alert("Redirection vers la création d'une nouvelle boîte.");
        // Ajouter la logique de navigation vers la page de création
    };

    const handleEditBox = (id) => {
        alert(`Modification de la boîte avec ID ${id}.`);
        // Ajouter la logique de navigation vers la page d'édition
    };

    const filteredData = data.filter(
        (item) =>
            item.name.toLowerCase().includes(filter) ||
            item.status.toLowerCase().includes(filter) ||
            item.type.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des emballages</h1>

            {/* Barre de recherche */}
            <div className="mb-4 flex justify-between">
                <input
                    type="text"
                    placeholder="Rechercher par nom, statut ou type..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
                <button
                    onClick={handleCreateBox}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 ml-4"
                >
                    Créer une boîte
                </button>
            </div>

            {/* Tableau des emballages */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Type</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.name}</td>
                                <td className="border border-gray-300 p-2">{item.type}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${item.status === "Prête à expédier"
                                            ? "text-green-500"
                                            : "text-orange-500"
                                        }`}
                                >
                                    {item.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleEditBox(item.id)}
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700 mr-2"
                                    >
                                        Modifier
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune donnée trouvée.</p>
            )}
        </div>
    );
};

export default PackagingList;
