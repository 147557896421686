export const maintenanceHistoryData = [
    {
        id: 1,
        equipment: "Machine A",
        type: "Préventif",
        technician: "Jean Dupont",
        date: "2024-11-15",
    },
    {
        id: 2,
        equipment: "Outil B",
        type: "Correctif",
        technician: "Marie Curie",
        date: "2024-11-18",
    },
    {
        id: 3,
        equipment: "Machine C",
        type: "Préventif",
        technician: "Albert Einstein",
        date: "2024-11-20",
    },
    {
        id: 4,
        equipment: "Machine D",
        type: "Correctif",
        technician: "Isaac Newton",
        date: "2024-11-22",
    },
];
