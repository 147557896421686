import React, { useState } from "react";
import { packagingResourcesData } from "../services/packagingData";

const PackagingResourcesTracking = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredResources = packagingResourcesData.filter(
        (resource) =>
            resource.name.toLowerCase().includes(filter) ||
            resource.type.toLowerCase().includes(filter) ||
            resource.status.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des ressources d’emballage</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, type ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des ressources */}
            {filteredResources.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Type</th>
                            <th className="border border-gray-300 p-2">Quantité</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredResources.map((resource) => (
                            <tr key={resource.id}>
                                <td className="border border-gray-300 p-2">{resource.name}</td>
                                <td className="border border-gray-300 p-2">{resource.type}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${resource.quantity < resource.criticalThreshold
                                            ? "text-red-500 font-bold"
                                            : "text-gray-700"
                                        }`}
                                >
                                    {resource.quantity}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {resource.quantity < resource.criticalThreshold ? (
                                        <span className="text-red-500 font-bold">Critique</span>
                                    ) : (
                                        <span className="text-green-500">Suffisant</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune ressource trouvée.</p>
            )}
        </div>
    );
};

export default PackagingResourcesTracking;
