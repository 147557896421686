import React, { useState } from "react";
import { nonConformingPiecesData } from "../services/nonConformingPiecesData";

const NonConformingPieces = () => {
    const [filter, setFilter] = useState("");
    const [pieces, setPieces] = useState(nonConformingPiecesData);

    const handleFilterChange = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    const filteredPieces = pieces.filter(
        (piece) =>
            piece.name.toLowerCase().includes(filter.toLowerCase()) ||
            piece.status.toLowerCase().includes(filter.toLowerCase()) ||
            piece.inspector.toLowerCase().includes(filter.toLowerCase())
    );

    const handleSendToCorrection = (id) => {
        alert(`La pièce avec ID ${id} a été envoyée en correction.`);
        setPieces(
            pieces.map((piece) =>
                piece.id === id ? { ...piece, status: "En correction" } : piece
            )
        );
    };

    const handleMarkAsReplaced = (id) => {
        alert(`La pièce avec ID ${id} a été marquée comme remplacée.`);
        setPieces(pieces.filter((piece) => piece.id !== id));
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des pièces non conformes</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, inspecteur ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des pièces non conformes */}
            {filteredPieces.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Inspecteur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPieces.map((piece) => (
                            <tr key={piece.id}>
                                <td className="border border-gray-300 p-2">{piece.name}</td>
                                <td className="border border-gray-300 p-2">{piece.inspector}</td>
                                <td className="border border-gray-300 p-2">{piece.status}</td>
                                <td className="border border-gray-300 p-2">
                                    {piece.status !== "En correction" && (
                                        <button
                                            onClick={() => handleSendToCorrection(piece.id)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                                        >
                                            Envoyer en correction
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleMarkAsReplaced(piece.id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                    >
                                        Marquer comme remplacée
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce non conforme trouvée.</p>
            )}
        </div>
    );
};

export default NonConformingPieces;
