import React from "react";
import { useNavigate } from "react-router-dom";
import { corrections } from "../services/correctionData";
import CorrectionTable from "../components/CorrectionTable";

const CorrectionList = () => {
    const navigate = useNavigate();

    const handleCorrectPiece = () => {
        navigate("/correct-piece");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Corrections</h1>
            <CorrectionTable corrections={corrections} />
            <button
                onClick={handleCorrectPiece}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Corriger une pièce
            </button>
        </div>
    );
};

export default CorrectionList;
