import React, { useState } from "react";
import { equipmentData } from "../services/equipmentData";

const MaintenancePlanning = () => {
    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [date, setDate] = useState("");
    const [technician, setTechnician] = useState("");
    const [plannedMaintenances, setPlannedMaintenances] = useState([]);

    const handleEquipmentSelection = (id) => {
        setSelectedEquipment((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((itemId) => itemId !== id)
                : [...prevSelected, id]
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectedEquipment.length || !date || !technician.trim()) {
            alert("Veuillez sélectionner au moins un matériel, une date, et un technicien.");
            return;
        }

        const newMaintenance = {
            id: plannedMaintenances.length + 1,
            equipment: selectedEquipment.map(
                (id) => equipmentData.find((equipment) => equipment.id === id)?.name
            ),
            date,
            technician,
        };

        setPlannedMaintenances((prevMaintenances) => [...prevMaintenances, newMaintenance]);

        // Réinitialiser le formulaire
        setSelectedEquipment([]);
        setDate("");
        setTechnician("");
        alert("Entretien planifié avec succès !");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Planification des entretiens</h1>

            {/* Formulaire de planification */}
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Planifier un entretien</h2>

                {/* Sélection des matériels */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Sélectionnez les matériels :</label>
                    <div className="grid grid-cols-2 gap-4">
                        {equipmentData.map((equipment) => (
                            <label key={equipment.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={selectedEquipment.includes(equipment.id)}
                                    onChange={() => handleEquipmentSelection(equipment.id)}
                                    className="mr-2"
                                />
                                {equipment.name} ({equipment.status})
                            </label>
                        ))}
                    </div>
                </div>

                {/* Date */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Date :</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                {/* Technicien */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Technicien :</label>
                    <input
                        type="text"
                        value={technician}
                        onChange={(e) => setTechnician(e.target.value)}
                        placeholder="Nom du technicien"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Planifier
                </button>
            </form>

            {/* Liste des entretiens planifiés */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Entretiens planifiés</h2>
                {plannedMaintenances.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">ID</th>
                                <th className="border border-gray-300 p-2">Matériel</th>
                                <th className="border border-gray-300 p-2">Date</th>
                                <th className="border border-gray-300 p-2">Technicien</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plannedMaintenances.map((maintenance) => (
                                <tr key={maintenance.id}>
                                    <td className="border border-gray-300 p-2">{maintenance.id}</td>
                                    <td className="border border-gray-300 p-2">
                                        {maintenance.equipment.join(", ")}
                                    </td>
                                    <td className="border border-gray-300 p-2">{maintenance.date}</td>
                                    <td className="border border-gray-300 p-2">{maintenance.technician}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucun entretien planifié.</p>
                )}
            </div>
        </div>
    );
};

export default MaintenancePlanning;
