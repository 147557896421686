export const cuttingMachines = [
    {
        id: 1,
        name: "Machine A",
        status: "En service",
        lastMaintenance: "2024-10-15",
    },
    {
        id: 2,
        name: "Machine B",
        status: "Hors service",
        lastMaintenance: "2024-09-20",
    },
    {
        id: 3,
        name: "Machine C",
        status: "En maintenance",
        lastMaintenance: "2024-11-10",
    },
    {
        id: 4,
        name: "Machine D",
        status: "En service",
        lastMaintenance: "2024-11-01",
    },
];
