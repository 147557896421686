export const defectData = [
    {
        id: 1,
        date: "2024-11-20",
        type: "Couture incorrecte",
        piece: "Pièce A",
        operator: "Jean Dupont",
        description: "La couture de la manche n'était pas alignée.",
    },
    {
        id: 2,
        date: "2024-11-21",
        type: "Coupe incorrecte",
        piece: "Pièce B",
        operator: "Marie Curie",
        description: "La découpe du tissu est asymétrique.",
    },
    {
        id: 3,
        date: "2024-11-22",
        type: "Matière première défectueuse",
        piece: "Pièce C",
        operator: "Paul Martin",
        description: "Tissu avec des défauts visibles.",
    },
    {
        id: 4,
        date: "2024-11-23",
        type: "Erreur d'assemblage",
        piece: "Pièce D",
        operator: "Alice Doe",
        description: "Assemblage des poches inversé.",
    },
];
