import React, { useState } from "react";
import { cuttingMachines } from "../services/cuttingMachinesData";

const MachineManagement = () => {
    const [filter, setFilter] = useState("Toutes"); // Filtre par statut
    const [machines, setMachines] = useState(cuttingMachines);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleToggleStatus = (id) => {
        const updatedMachines = machines.map((machine) =>
            machine.id === id
                ? {
                    ...machine,
                    status: machine.status === "En service" ? "Hors service" : "En service",
                }
                : machine
        );
        setMachines(updatedMachines);
    };

    const filteredMachines =
        filter === "Toutes"
            ? machines
            : machines.filter((machine) => machine.status === filter);

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des machines de découpe</h1>

            {/* Filtres */}
            <div className="mb-4">
                <label htmlFor="statusFilter" className="mr-4 font-semibold">
                    Filtrer par statut :
                </label>
                <select
                    id="statusFilter"
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2"
                >
                    <option value="Toutes">Toutes</option>
                    <option value="En service">En service</option>
                    <option value="Hors service">Hors service</option>
                    <option value="En maintenance">En maintenance</option>
                </select>
            </div>

            {/* Tableau des machines */}
            {filteredMachines.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Dernière maintenance</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMachines.map((machine) => (
                            <tr key={machine.id}>
                                <td className="border border-gray-300 p-2">{machine.name}</td>
                                <td className="border border-gray-300 p-2">{machine.status}</td>
                                <td className="border border-gray-300 p-2">{machine.lastMaintenance}</td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleToggleStatus(machine.id)}
                                        className={`px-3 py-1 rounded text-white ${machine.status === "En service" ? "bg-red-500" : "bg-green-500"
                                            } hover:bg-opacity-75`}
                                    >
                                        {machine.status === "En service" ? "Mettre hors service" : "Remettre en service"}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune machine trouvée.</p>
            )}
        </div>
    );
};

export default MachineManagement;
