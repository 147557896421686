import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validationData } from "../services/validationData";

const BatchValidation = () => {
    const navigate = useNavigate();
    const [selectedPieces, setSelectedPieces] = useState([]);
    const [filter, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState(validationData);

    const handleFilterChange = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);

        // Appliquer les filtres sur les données
        const filtered = validationData.filter(
            (item) =>
                item.name.toLowerCase().includes(filterValue) ||
                item.status.toLowerCase().includes(filterValue) ||
                item.operator.toLowerCase().includes(filterValue)
        );
        setFilteredData(filtered);
    };

    const handleSelect = (id) => {
        if (selectedPieces.includes(id)) {
            setSelectedPieces(selectedPieces.filter((pieceId) => pieceId !== id));
        } else {
            setSelectedPieces([...selectedPieces, id]);
        }
    };

    const handleValidateBatch = () => {
        if (selectedPieces.length === 0) {
            alert("Veuillez sélectionner au moins une pièce à valider.");
            return;
        }
        alert(`${selectedPieces.length} pièce(s) validée(s) avec succès !`);
        setFilteredData(
            filteredData.map((item) =>
                selectedPieces.includes(item.id) ? { ...item, status: "Validée" } : item
            )
        );
        setSelectedPieces([]);
    };

    const handleRejectBatch = () => {
        if (selectedPieces.length === 0) {
            alert("Veuillez sélectionner au moins une pièce à rejeter.");
            return;
        }
        navigate(`/report-defects/${selectedPieces.join(",")}`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Validation par lot</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, statut ou opérateur..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des pièces */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        setSelectedPieces(
                                            e.target.checked ? filteredData.map((item) => item.id) : []
                                        )
                                    }
                                    checked={
                                        selectedPieces.length > 0 &&
                                        selectedPieces.length === filteredData.length
                                    }
                                />
                            </th>
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedPieces.includes(item.id)}
                                        onChange={() => handleSelect(item.id)}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">{item.name}</td>
                                <td className="border border-gray-300 p-2">{item.status}</td>
                                <td className="border border-gray-300 p-2">{item.operator}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce trouvée.</p>
            )}

            {/* Actions */}
            <div className="flex justify-between mt-6">
                <button
                    onClick={handleValidateBatch}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Valider la sélection
                </button>
                <button
                    onClick={handleRejectBatch}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Rejeter la sélection
                </button>
            </div>
        </div>
    );
};

export default BatchValidation;
