export const correctionsValidationData = [
    {
        id: 1,
        pieceName: "Pièce A",
        operator: "Jean Dupont",
        status: "Prête pour validation",
    },
    {
        id: 2,
        pieceName: "Pièce B",
        operator: "Marie Curie",
        status: "Prête pour validation",
    },
    {
        id: 3,
        pieceName: "Pièce C",
        operator: "Paul Martin",
        status: "Validée",
    },
];
