import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const ShippingDashboard = () => {
    // Données pour les KPI
    const stats = {
        totalShipments: 250,
        averageDelay: 2.5, // en jours
        anomalyRate: 8, // pourcentage
        shipmentsByStatus: {
            delivered: 180,
            inTransit: 50,
            delayed: 15,
            missing: 5,
        },
        anomalyTypes: [
            { type: "Boîtes endommagées", count: 30 },
            { type: "Boîtes manquantes", count: 10 },
            { type: "Retards", count: 20 },
        ],
    };

    // Données pour les graphiques
    const pieData = {
        labels: ["Livrées", "En transit", "Retardées", "Manquantes"],
        datasets: [
            {
                data: [
                    stats.shipmentsByStatus.delivered,
                    stats.shipmentsByStatus.inTransit,
                    stats.shipmentsByStatus.delayed,
                    stats.shipmentsByStatus.missing,
                ],
                backgroundColor: ["#4caf50", "#2196f3", "#ff9800", "#f44336"],
            },
        ],
    };

    const barData = {
        labels: stats.anomalyTypes.map((item) => item.type),
        datasets: [
            {
                label: "Types d'anomalies",
                data: stats.anomalyTypes.map((item) => item.count),
                backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des expéditions</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Total des expéditions</h2>
                    <p className="text-3xl font-bold">{stats.totalShipments}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Délai moyen</h2>
                    <p className="text-3xl font-bold text-blue-500">{stats.averageDelay} jours</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux d'anomalies</h2>
                    <p className="text-3xl font-bold text-red-500">{stats.anomalyRate}%</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Statut des expéditions</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Types d'anomalies</h2>
                    <Bar data={barData} />
                </div>
            </div>
        </div>
    );
};

export default ShippingDashboard;
