import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { qualityInspectionData } from "../services/qualityInspectionData";

const QualityInspectionList = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [pieces, setPieces] = useState(qualityInspectionData);

    const handleFilterChange = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    const filteredPieces = pieces.filter(
        (piece) =>
            piece.name.toLowerCase().includes(filter.toLowerCase()) ||
            piece.status.toLowerCase().includes(filter.toLowerCase()) ||
            piece.inspector.toLowerCase().includes(filter.toLowerCase())
    );

    const handleMarkConforming = (id) => {
        alert(`La pièce avec ID ${id} a été marquée comme conforme.`);
        setPieces(
            pieces.map((piece) =>
                piece.id === id ? { ...piece, status: "Conforme" } : piece
            )
        );
    };

    const handleReportDefect = (id) => {
        navigate(`/report-quality-defects/${id}`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Liste des inspections qualité</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, statut ou inspecteur..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des inspections */}
            {filteredPieces.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Inspecteur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPieces.map((piece) => (
                            <tr key={piece.id}>
                                <td className="border border-gray-300 p-2">{piece.name}</td>
                                <td className="border border-gray-300 p-2">{piece.inspector}</td>
                                <td className="border border-gray-300 p-2">{piece.status}</td>
                                <td className="border border-gray-300 p-2">
                                    {piece.status !== "Conforme" && (
                                        <button
                                            onClick={() => handleMarkConforming(piece.id)}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                        >
                                            Marquer conforme
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleReportDefect(piece.id)}
                                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        Signaler un défaut
                                    </button>
                                    <td className="border border-gray-300 p-2">
                                        <button
                                            onClick={() => navigate(`/quality-inspection-details/${piece.id}`)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                        >
                                            Voir détails
                                        </button>
                                    </td>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce à inspecter trouvée.</p>
            )}
        </div>
    );
};

export default QualityInspectionList;
