import React, { useState } from "react";
import { anomalies } from "../services/cuttingAnomaliesData";

const ManageAnomalies = () => {
    const [anomalyList, setAnomalyList] = useState(anomalies);
    const [form, setForm] = useState({
        description: "",
        taskId: "",
        machineId: "",
        operator: "",
        status: "Signalée", // Statut par défaut
    });
    const [error, setError] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleAddAnomaly = (e) => {
        e.preventDefault();

        // Validation des champs
        if (!form.description || !form.taskId || !form.machineId || !form.operator) {
            setError("Tous les champs sont obligatoires.");
            return;
        }

        const newAnomaly = {
            id: anomalyList.length + 1,
            ...form,
            date: new Date().toISOString().split("T")[0], // Date du jour
        };

        setAnomalyList([...anomalyList, newAnomaly]);
        setForm({
            description: "",
            taskId: "",
            machineId: "",
            operator: "",
            status: "Signalée",
        });
        setError("");
    };

    const handleResolveAnomaly = (id) => {
        const updatedList = anomalyList.map((anomaly) =>
            anomaly.id === id ? { ...anomaly, status: "Résolue" } : anomaly
        );
        setAnomalyList(updatedList);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des anomalies</h1>

            {/* Formulaire pour signaler une anomalie */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Signaler une anomalie</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleAddAnomaly} className="space-y-4">
                    <div>
                        <label className="block mb-2 font-semibold">Description :</label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleInputChange}
                            placeholder="Décrivez l'anomalie"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">ID de la tâche :</label>
                        <input
                            type="text"
                            name="taskId"
                            value={form.taskId}
                            onChange={handleInputChange}
                            placeholder="ID de la tâche concernée"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">ID de la machine :</label>
                        <input
                            type="text"
                            name="machineId"
                            value={form.machineId}
                            onChange={handleInputChange}
                            placeholder="ID de la machine concernée"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Opérateur :</label>
                        <input
                            type="text"
                            name="operator"
                            value={form.operator}
                            onChange={handleInputChange}
                            placeholder="Nom de l'opérateur"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                    >
                        Signaler
                    </button>
                </form>
            </div>

            {/* Tableau des anomalies */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Liste des anomalies</h2>
                {anomalyList.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Date</th>
                                <th className="border border-gray-300 p-2">Description</th>
                                <th className="border border-gray-300 p-2">Tâche</th>
                                <th className="border border-gray-300 p-2">Machine</th>
                                <th className="border border-gray-300 p-2">Opérateur</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                                <th className="border border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {anomalyList.map((anomaly) => (
                                <tr key={anomaly.id}>
                                    <td className="border border-gray-300 p-2">{anomaly.date}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.description}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.taskId}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.machineId}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.operator}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.status}</td>
                                    <td className="border border-gray-300 p-2">
                                        {anomaly.status === "Signalée" && (
                                            <button
                                                onClick={() => handleResolveAnomaly(anomaly.id)}
                                                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                            >
                                                Marquer comme résolue
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune anomalie signalée.</p>
                )}
            </div>
        </div>
    );
};

export default ManageAnomalies;
