import React, { useState } from "react";
import { operators } from "../services/operatorData";

const OperatorTracking = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredOperators = operators.filter((operator) =>
        operator.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des opérateurs</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom d'opérateur..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des opérateurs */}
            {filteredOperators.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Tâches effectuées</th>
                            <th className="border border-gray-300 p-2">Taux de défauts (%)</th>
                            <th className="border border-gray-300 p-2">Dernière tâche</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOperators.map((operator) => (
                            <tr key={operator.id}>
                                <td className="border border-gray-300 p-2">{operator.name}</td>
                                <td className="border border-gray-300 p-2">{operator.completedTasks}</td>
                                <td className="border border-gray-300 p-2">{operator.defectRate}</td>
                                <td className="border border-gray-300 p-2">
                                    {operator.lastTask || "Aucune tâche récente"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun opérateur trouvé.</p>
            )}
        </div>
    );
};

export default OperatorTracking;
