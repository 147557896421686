import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const PackagingDashboard = () => {
    // Données pour les KPI
    const stats = {
        totalBoxes: 150,
        errorRate: 5, // En pourcentage
        avgResourcesConsumed: 120, // Moyenne de consommation de ressources
        boxesByStatus: {
            readyToShip: 80,
            pending: 50,
            shipped: 20,
        },
        resourceDistribution: [
            { resource: "Cartons", count: 500 },
            { resource: "Étiquettes", count: 300 },
            { resource: "Ruban adhésif", count: 200 },
        ],
    };

    // Données pour les graphiques
    const pieData = {
        labels: ["Prêtes à expédier", "En attente", "Expédiées"],
        datasets: [
            {
                data: [
                    stats.boxesByStatus.readyToShip,
                    stats.boxesByStatus.pending,
                    stats.boxesByStatus.shipped,
                ],
                backgroundColor: ["#4caf50", "#ff9800", "#2196f3"],
            },
        ],
    };

    const barData = {
        labels: stats.resourceDistribution.map((item) => item.resource),
        datasets: [
            {
                label: "Consommation des ressources",
                data: stats.resourceDistribution.map((item) => item.count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des emballages</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Total des boîtes</h2>
                    <p className="text-3xl font-bold">{stats.totalBoxes}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux d'erreur</h2>
                    <p className="text-3xl font-bold text-red-500">{stats.errorRate}%</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Consommation moyenne</h2>
                    <p className="text-3xl font-bold">{stats.avgResourcesConsumed} unités</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Statut des boîtes</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Consommation des ressources</h2>
                    <Bar data={barData} />
                </div>
            </div>
        </div>
    );
};

export default PackagingDashboard;
