export const cuttingTasks = [
    {
        id: 1,
        name: "Découpe de tissu A",
        startDate: "2024-11-20",
        operator: "Jean Dupont",
        status: "En cours",
    },
    {
        id: 2,
        name: "Découpe de tissu B",
        startDate: "2024-11-18",
        operator: "Marie Curie",
        status: "Terminée",
    },
    {
        id: 3,
        name: "Découpe de tissu C",
        startDate: "2024-11-22",
        operator: "Paul Martin",
        status: "Planifiée",
    },
];
