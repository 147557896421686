export const correctionsHistoryData = [
    {
        id: 1,
        date: "2024-11-20",
        pieceName: "Pièce A",
        type: "Correction",
        operator: "Jean Dupont",
        status: "Validée",
    },
    {
        id: 2,
        date: "2024-11-21",
        pieceName: "Pièce B",
        type: "Remplacement",
        operator: "Marie Curie",
        status: "Rejetée à nouveau",
    },
    {
        id: 3,
        date: "2024-11-22",
        pieceName: "Pièce C",
        type: "Correction",
        operator: "Paul Martin",
        status: "En attente de validation",
    },
];
