import React, { useState } from "react";
import { maintenanceHistoryData } from "../services/maintenanceData";

const MaintenanceHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Filtrage des données
    const filteredData = maintenanceHistoryData.filter(
        (entry) =>
            entry.equipment.toLowerCase().includes(filter.toLowerCase()) ||
            entry.technician.toLowerCase().includes(filter.toLowerCase()) ||
            entry.type.toLowerCase().includes(filter.toLowerCase())
    );

    // Pagination
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Exporter les données au format CSV
    const handleExport = () => {
        const csvContent = `ID,Matériel,Type d'entretien,Technicien,Date\n${filteredData
            .map(
                (entry) =>
                    `${entry.id},${entry.equipment},${entry.type},${entry.technician},${entry.date}`
            )
            .join("\n")}`;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "maintenance_history.csv");
        link.click();
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des entretiens</h1>

            {/* Barre de recherche */}
            <div className="mb-4 flex justify-between items-center">
                <input
                    type="text"
                    placeholder="Rechercher par matériel, technicien ou type d'entretien..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="border border-gray-300 rounded p-2 w-2/3"
                />
                <button
                    onClick={handleExport}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Exporter CSV
                </button>
            </div>

            {/* Tableau des entretiens */}
            {paginatedData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">ID</th>
                            <th className="border border-gray-300 p-2">Matériel</th>
                            <th className="border border-gray-300 p-2">Type d'entretien</th>
                            <th className="border border-gray-300 p-2">Technicien</th>
                            <th className="border border-gray-300 p-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((entry) => (
                            <tr key={entry.id}>
                                <td className="border border-gray-300 p-2">{entry.id}</td>
                                <td className="border border-gray-300 p-2">{entry.equipment}</td>
                                <td className="border border-gray-300 p-2">{entry.type}</td>
                                <td className="border border-gray-300 p-2">{entry.technician}</td>
                                <td className="border border-gray-300 p-2">{entry.date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun entretien trouvé.</p>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 border ${currentPage === index + 1
                                    ? "bg-blue-500 text-white"
                                    : "bg-white text-blue-500"
                                } hover:bg-blue-700 hover:text-white mx-1`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MaintenanceHistory;
