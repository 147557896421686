export const machines = [
    {
        id: 1,
        name: "Machine A",
        status: "En service",
        tasks: "Découpe tissu A",
    },
    {
        id: 2,
        name: "Machine B",
        status: "En maintenance",
        tasks: "Assemblage poches",
    },
    {
        id: 3,
        name: "Machine C",
        status: "Hors service",
        tasks: null,
    },
];
