import React from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const QualityControlDashboard = () => {
    // Données pour les KPI
    const dashboardStats = {
        totalInspections: 200,
        defectRate: 15, // En pourcentage
        averageInspectionTime: 8, // En minutes
        inspectionsByStatus: {
            conforming: 170,
            nonConforming: 30,
        },
        defectsByType: [
            { type: "Couture incorrecte", count: 50 },
            { type: "Coupe incorrecte", count: 30 },
            { type: "Matière première défectueuse", count: 20 },
        ],
    };

    // Données pour les graphiques
    const pieData = {
        labels: ["Conformes", "Non conformes"],
        datasets: [
            {
                data: [
                    dashboardStats.inspectionsByStatus.conforming,
                    dashboardStats.inspectionsByStatus.nonConforming,
                ],
                backgroundColor: ["#4caf50", "#f44336"],
            },
        ],
    };

    const barData = {
        labels: dashboardStats.defectsByType.map((defect) => defect.type),
        datasets: [
            {
                label: "Nombre de défauts par type",
                data: dashboardStats.defectsByType.map((defect) => defect.count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const lineData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Tendances des inspections mensuelles",
                data: [10, 20, 15, 30, 25, 40, 35, 50, 45, 60, 70, 80],
                fill: false,
                borderColor: "rgba(75, 192, 192, 1)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord du contrôle qualité</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Total des inspections</h2>
                    <p className="text-3xl font-bold">{dashboardStats.totalInspections}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux de défauts</h2>
                    <p className="text-3xl font-bold text-red-500">{dashboardStats.defectRate}%</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Temps moyen d'inspection</h2>
                    <p className="text-3xl font-bold">{dashboardStats.averageInspectionTime} min</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des inspections</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Défauts par type</h2>
                    <Bar data={barData} />
                </div>
            </div>

            {/* Graphique linéaire */}
            <div className="bg-white shadow-md rounded p-4 mt-6">
                <h2 className="text-lg font-bold mb-4">Tendances des inspections mensuelles</h2>
                <Line data={lineData} />
            </div>
        </div>
    );
};

export default QualityControlDashboard;
