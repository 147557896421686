import React, { useState } from "react";
import { Link } from "react-router-dom";
import { materials } from "../../modules/Materials/services/materialsData";

import "./Layout.css";

const Navbar = () => {
    const CRITICAL_THRESHOLD = 100;
    const criticalCount = materials.filter(
        (material) => material.quantity < CRITICAL_THRESHOLD
    ).length;

    // État pour gérer l'affichage des sous-menus
    const [showSubmenu, setShowSubmenu] = useState({});

    const toggleSubmenu = (section) => {
        setShowSubmenu((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/">Textile Manager</Link>
            </div>
            <ul className="navbar-links">
                {/* Matières premières */}
                <li>
                    <span onClick={() => toggleSubmenu("materials")}>Matières premières</span>
                    {showSubmenu["materials"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/">Gestion des matières</Link>
                            </li>
                            <li>
                                <Link to="/critical-thresholds">
                                    Seuils critiques
                                    {criticalCount > 0 && (
                                        <span className="badge">{criticalCount}</span>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link to="/suppliers">Fournisseurs</Link>
                            </li>
                            <li>
                                <Link to="/movement-history">Historique des mouvements</Link>
                            </li>
                            <li>
                                <Link to="/materials-dashboard">Tableau de bord</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Production */}
                <li>
                    <span onClick={() => toggleSubmenu("production")}>Production</span>
                    {showSubmenu["production"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/production-tasks">Tâches en production</Link>
                            </li>
                            <li>
                                <Link to="/task-assignment">Attribution des tâches</Link>
                            </li>
                            <li>
                                <Link to="/real-time-production">Suivi en temps réel</Link>
                            </li>
                            <li>
                                <Link to="/production-history">Historique de production</Link>
                            </li>
                            <li>
                                <Link to="/production-dashboard">Tableau de bord</Link>
                            </li>
                            <li>
                                <Link to="/manage-production-anomalies">Gestion des anomalies</Link>
                            </li>
                            <li>
                                <Link to="/machine-tracking">Suivi des machines</Link>
                            </li>
                            <li>
                                <Link to="/operator-tracking">Suivi des opérateurs</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Découpe */}
                <li>
                    <span onClick={() => toggleSubmenu("cutting")}>Découpe</span>
                    {showSubmenu["cutting"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/cutting-tasks">Tâches de découpe</Link>
                            </li>
                            <li>
                                <Link to="/machine-management">Machines de découpe</Link>
                            </li>
                            <li>
                                <Link to="/manage-anomalies">Gestion des anomalies</Link>
                            </li>
                            <li>
                                <Link to="/cutting-history">Historique des découpes</Link>
                            </li>
                            <li>
                                <Link to="/resource-allocation">Allocation des ressources</Link>
                            </li>
                            <li>
                                <Link to="/cutting-dashboard">Tableau de bord de découpe</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Assemblage */}
                <li>
                    <span onClick={() => toggleSubmenu("assembly")}>Assemblage</span>
                    {showSubmenu["assembly"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/assembly-tasks">Tâches d'assemblage</Link>
                            </li>
                            <li>
                                <Link to="/ingredient-usage">Suivi des ingrédients</Link>
                            </li>
                            <li>
                                <Link to="/resource-allocation">Allocation des ressources</Link>
                            </li>
                            <li>
                                <Link to="/assembly-history">Historique des assemblages</Link>
                            </li>
                            <li>
                                <Link to="/manage-assembly-anomalies">Gestion des anomalies</Link>
                            </li>
                            <li>
                                <Link to="/assembly-dashboard">Tableau de bord</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Contrôle & Emballage */}
                <li>
                    <span onClick={() => toggleSubmenu("control")}>Contrôle & Emballage</span>
                    {showSubmenu["control"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/packaging-list">Gestion des emballages</Link>
                            </li>
                            <li>
                                <Link to="/package-box">Créer une boîte</Link>
                            </li>
                            <li>
                                <Link to="/packaged-boxes-tracking">Suivi des boîtes emballées</Link>
                            </li>
                            <li>
                                <Link to="/packaging-resources-tracking">Suivi des ressources d’emballage</Link>
                            </li>
                            <li>
                                <Link to="/packaging-anomalies">Gestion des anomalies d’emballage</Link>
                            </li>
                            <li>
                                <Link to="/packaging-history">Historique des emballages</Link>
                            </li>
                            <li>
                                <Link to="/packaging-dashboard">Tableau de bord des emballages</Link>
                            </li>
                            <li>
                                <Link to="/label-document-generation">Génération des étiquettes et documents</Link>
                            </li>


                        </ul>
                    )}
                </li>

                {/* Gestion des matériels */}
                <li>
                    <span onClick={() => toggleSubmenu("equipmentManagement")}>
                        Gestion des matériels
                    </span>
                    {showSubmenu["equipmentManagement"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/equipment-list">Liste des matériels</Link>
                            </li>
                            <li>
                                <Link to="/real-time-equipment">Suivi en temps réel</Link>
                            </li>
                            <li>
                                <Link to="/maintenance-planning">Planification des entretiens</Link>
                            </li>
                            <li>
                                <Link to="/maintenance-history">Historique des entretiens</Link>
                            </li>
                            <li>
                                <Link to="/equipment-breakdowns">Gestion des pannes</Link>
                            </li>
                            <li>
                                <Link to="/equipment-dashboard">Tableau de bord</Link>
                            </li>
                            <li>
                                <Link to="/equipment-lifecycle">Cycle de vie</Link>
                            </li>
                            <li>
                                <Link to="/spare-parts-management">Pièces de rechange</Link>
                            </li>
                        </ul>
                    )}
                </li>
                {/* Validation */}
                <li>
                    <span onClick={() => toggleSubmenu("validation")}>Validation</span>
                    {showSubmenu["validation"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/internal-validations">Validations internes</Link>
                            </li>
                            <li>
                                <Link to="/validation-history">Historique des validations</Link>
                            </li>
                            <li>
                                <Link to="/batch-validation">Validation par lot</Link>
                            </li>
                            <li>
                                <Link to="/validation-dashboard">Tableau de bord des validations</Link>
                            </li>
                            <li>
                                <Link to="/validation-history">Historique des validations</Link>
                            </li>
                            <li>
                                <Link to="/rejected-pieces">Pièces rejetées</Link>
                            </li>
                            <li>
                                <Link to="/defects-report">Rapport des défauts</Link>
                            </li>
                        </ul>
                    )}
                </li>
                {/* Contrôle Qualité Menu */}
                <li>
                    <span onClick={() => toggleSubmenu("qualityControl")}>Contrôle Qualité</span>
                    {showSubmenu["qualityControl"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/quality-inspections">Inspections qualité</Link>
                            </li>
                            <li>
                                <Link to="/non-conforming-pieces">Pièces non conformes</Link>
                            </li>
                            <li>
                                <Link to="/validation-corrections">Validation des corrections</Link>
                            </li>
                            <li>
                                <Link to="/quality-control-dashboard">Tableau de bord</Link>
                            </li>
                            <li>
                                <Link to="/quality-control-history">Historique des contrôles</Link>
                            </li>
                            <li>
                                <Link to="/quality-defects-report">Rapport des anomalies</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Corrections & Remplacements Menu */}
                <li>
                    <span onClick={() => toggleSubmenu("corrections")}>Corrections & Remplacements</span>
                    {showSubmenu["corrections"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/rejected-pieces">Pièces rejetées</Link>
                            </li>
                            <li>
                                <Link to="/report-defects-for-correction">Signalement des anomalies</Link>
                            </li>
                            <li>
                                <Link to="/plan-corrections">Planification des corrections</Link>
                            </li>
                            <li>
                                <Link to="/corrections-tracking">Suivi des corrections</Link>
                            </li>
                            <li>
                                <Link to="/replacements-management">Gestion des remplacements</Link>
                            </li>
                            <li>
                                <Link to="/corrections-validation">Validation des corrections</Link>
                            </li>
                            <li>
                                <Link to="/corrections-history">Historique des corrections</Link>
                            </li>
                            <li>
                                <Link to="/corrections-dashboard">Tableau de bord des corrections</Link>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Expéditions */}
                <li>
                    <span onClick={() => toggleSubmenu("shipping")}>Expéditions</span>
                    {showSubmenu["shipping"] && (
                        <ul className="submenu">
                            <li>
                                <Link to="/shipping-list">Gestion des expéditions</Link>
                            </li>
                            <li>
                                <Link to="/prepare-shipment">Préparation des expéditions</Link>
                            </li>
                            <li>
                                <Link to="/shipping-tracking">Suivi des expéditions</Link>
                            </li>
                            <li>
                                <Link to="/shipping-anomalies">Gestion des anomalies d’expédition</Link>
                            </li>
                            <li>
                                <Link to="/shipping-history">Historique des expéditions</Link>
                            </li>
                            <li>
                                <Link to="/shipping-dashboard">Tableau de bord des expéditions</Link>
                            </li>
                            <li>
                                <Link to="/generate-shipping-documents">Génération des documents</Link>
                            </li>
                        </ul>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
