export const qualityDefectsData = [
    {
        id: 1,
        date: "2024-11-20",
        type: "Couture incorrecte",
        operator: "Jean Dupont",
        piece: "Pièce A",
        description: "Défaut mineur dans la couture.",
    },
    {
        id: 2,
        date: "2024-11-21",
        type: "Coupe incorrecte",
        operator: "Marie Curie",
        piece: "Pièce B",
        description: "Découpe asymétrique du tissu.",
    },
    {
        id: 3,
        date: "2024-11-22",
        type: "Matière première défectueuse",
        operator: "Paul Martin",
        piece: "Pièce C",
        description: "Tissu avec des défauts visibles.",
    },
];
