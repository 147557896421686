import React from "react";
import { useNavigate } from "react-router-dom";
import { tasks } from "../services/ProductionData";
import ProductionTable from "../components/ProductionTable";

const ProductionList = () => {
    const navigate = useNavigate();

    const handleAssignTask = () => {
        navigate("/assign-task"); // Redirige vers la page d'assignation
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Tâches de production</h1>
            <ProductionTable tasks={tasks} />
            <button
                onClick={handleAssignTask}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Assigner une tâche
            </button>
        </div>
    );
};

export default ProductionList;
