export const anomalies = [
    {
        id: 1,
        description: "Dysfonctionnement de la machine",
        taskId: "123",
        machineId: "A1",
        operator: "Jean Dupont",
        status: "Signalée",
        date: "2024-11-22",
    },
    {
        id: 2,
        description: "Découpe incorrecte sur le tissu B",
        taskId: "124",
        machineId: "B2",
        operator: "Marie Curie",
        status: "Résolue",
        date: "2024-11-20",
    },
];
