export const assemblyTasks = [
    {
        id: 1,
        name: "Assemblage des manches",
        startDate: "2024-11-20",
        operator: "Jean Dupont",
        ingredients: ["Fils de coton", "Boutons"],
        status: "En cours",
    },
    {
        id: 2,
        name: "Assemblage des poches",
        startDate: "2024-11-18",
        operator: "Marie Curie",
        ingredients: ["Élastiques", "Fils de coton"],
        status: "Terminée",
    },
    {
        id: 3,
        name: "Assemblage des cols",
        startDate: "2024-11-22",
        operator: "Paul Martin",
        ingredients: ["Fils de coton"],
        status: "Planifiée",
    },
];
