import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { assemblyTasks } from "../services/assemblyTasksData";
import { ingredients } from "../services/ingredientsData";

const AssemblyTaskDetailsPage = () => {
    const { id } = useParams(); // Récupère l'ID de la tâche depuis l'URL
    const navigate = useNavigate();

    // Recherche de la tâche par ID
    const task = assemblyTasks.find((task) => task.id === parseInt(id));

    if (!task) {
        return <p className="text-center text-red-500">Tâche non trouvée.</p>;
    }

    const handleEdit = () => {
        navigate(`/edit-assembly-task/${id}`);
    };

    const handleDelete = () => {
        const confirmed = window.confirm(
            "Êtes-vous sûr de vouloir supprimer cette tâche ?"
        );
        if (confirmed) {
            console.log(`Tâche supprimée : ${task.name}`);
            navigate("/assembly-tasks");
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de la tâche d'assemblage</h1>

            {/* Informations générales */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Informations générales</h2>
                <p><strong>Nom :</strong> {task.name}</p>
                <p><strong>Date de début :</strong> {task.startDate}</p>
                <p><strong>Opérateur :</strong> {task.operator}</p>
                <p><strong>Statut :</strong> {task.status}</p>
            </div>

            {/* Ingrédients utilisés */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Ingrédients utilisés</h2>
                {task.ingredients.length > 0 ? (
                    <ul className="list-disc ml-4">
                        {task.ingredients.map((ingredientName, index) => {
                            const ingredient = ingredients.find(
                                (ing) => ing.name === ingredientName
                            );
                            return (
                                <li key={index}>
                                    {ingredient ? (
                                        <>
                                            {ingredient.name} ({ingredient.quantity} {ingredient.unit})
                                        </>
                                    ) : (
                                        ingredientName
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p>Aucun ingrédient assigné.</p>
                )}
            </div>

            {/* Historique des modifications */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Historique des modifications</h2>
                {/* Remplacez par des données dynamiques */}
                <ul className="list-disc ml-4">
                    <li>2024-11-20 : Création de la tâche par Jean Dupont</li>
                    <li>2024-11-22 : Statut mis à jour à "En cours"</li>
                </ul>
            </div>

            {/* Actions */}
            <div className="flex justify-between">
                <button
                    onClick={handleEdit}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Modifier
                </button>
                <button
                    onClick={handleDelete}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Supprimer
                </button>
            </div>
        </div>
    );
};

export default AssemblyTaskDetailsPage;
