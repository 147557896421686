import React, { useState, useEffect } from "react";
import { equipmentData } from "../services/equipmentData";

const EquipmentLifecycle = () => {
    const [equipments, setEquipments] = useState(
        equipmentData.map((equipment) => ({
            ...equipment,
            remainingLife: equipment.totalLife - equipment.usage,
            alert: "OK",
        }))
    );

    useEffect(() => {
        // Mettez à jour les alertes pour les matériels
        const updatedEquipments = equipments.map((equipment) => {
            const remainingLife = equipment.totalLife - equipment.usage;
            const alert =
                remainingLife <= 10
                    ? "Durée de vie critique"
                    : remainingLife <= 30
                        ? "Durée de vie limitée"
                        : "OK";
            return { ...equipment, remainingLife, alert };
        });

        setEquipments(updatedEquipments);
    }, []); // Dépendance vide pour exécuter le code seulement une fois

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Cycle de vie des matériels</h1>

            {/* Tableau des cycles de vie */}
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Nom</th>
                        <th className="border border-gray-300 p-2">Durée totale (heures)</th>
                        <th className="border border-gray-300 p-2">Utilisation (heures)</th>
                        <th className="border border-gray-300 p-2">Durée restante (heures)</th>
                        <th className="border border-gray-300 p-2">Alerte</th>
                    </tr>
                </thead>
                <tbody>
                    {equipments.map((equipment) => (
                        <tr key={equipment.id}>
                            <td className="border border-gray-300 p-2">{equipment.name}</td>
                            <td className="border border-gray-300 p-2">{equipment.totalLife}</td>
                            <td className="border border-gray-300 p-2">{equipment.usage}</td>
                            <td className="border border-gray-300 p-2">{equipment.remainingLife}</td>
                            <td
                                className={`border border-gray-300 p-2 ${equipment.alert === "Durée de vie critique"
                                        ? "text-red-500"
                                        : equipment.alert === "Durée de vie limitée"
                                            ? "text-orange-500"
                                            : "text-green-500"
                                    }`}
                            >
                                {equipment.alert}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EquipmentLifecycle;
