import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { qualityInspectionData } from "../services/qualityInspectionData";
import { qualityDefectsData } from "../services/qualityDefectsData";

const ReportQualityDefects = () => {
    const { id } = useParams(); // ID de la pièce
    const navigate = useNavigate();

    const piece = qualityInspectionData.find((item) => item.id === parseInt(id));

    const [defects, setDefects] = useState(
        qualityDefectsData.filter((defect) => defect.pieceId === parseInt(id))
    );

    const [form, setForm] = useState({
        description: "",
    });

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.description.trim() === "") {
            alert("Veuillez fournir une description du défaut.");
            return;
        }

        const newDefect = {
            id: qualityDefectsData.length + 1,
            pieceId: parseInt(id),
            date: new Date().toISOString().split("T")[0], // Date actuelle
            description: form.description,
            status: "En attente",
        };

        setDefects([...defects, newDefect]);
        setForm({ description: "" });
        alert("Défaut signalé avec succès !");
    };

    if (!piece) {
        return <p className="text-center text-red-500">Pièce non trouvée.</p>;
    }

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Signaler un défaut</h1>

            {/* Informations sur la pièce */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Informations sur la pièce</h2>
                <p>
                    <strong>Nom :</strong> {piece.name}
                </p>
                <p>
                    <strong>Inspecteur :</strong> {piece.inspector}
                </p>
            </div>

            {/* Formulaire de signalement */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Formulaire de signalement</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Description du défaut :</label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleInputChange}
                            placeholder="Décrivez le défaut détecté"
                            className="border border-gray-300 rounded p-2 w-full"
                            rows="4"
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    >
                        Signaler
                    </button>
                </form>
            </div>

            {/* Historique des défauts */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Historique des défauts</h2>
                {defects.length > 0 ? (
                    <ul>
                        {defects.map((defect) => (
                            <li key={defect.id} className="mb-2">
                                <strong>Date :</strong> {defect.date} <br />
                                <strong>Description :</strong> {defect.description} <br />
                                <strong>Statut :</strong>{" "}
                                <span
                                    className={
                                        defect.status === "Corrigé" ? "text-green-500" : "text-orange-500"
                                    }
                                >
                                    {defect.status}
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucun défaut signalé pour cette pièce.</p>
                )}
            </div>

            {/* Bouton Retour */}
            <div className="mt-4">
                <button
                    onClick={() => navigate("/quality-inspections")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                >
                    Retour aux inspections
                </button>
            </div>
        </div>
    );
};

export default ReportQualityDefects;
