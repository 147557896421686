import React, { useState } from "react";
import { assemblyHistory } from "../services/assemblyHistoryData";

const AssemblyHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Nombre d'éléments par page

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Réinitialiser la pagination lors du changement de filtre
    };

    const filteredHistory = assemblyHistory.filter(
        (entry) =>
            entry.piece.toLowerCase().includes(filter.toLowerCase()) ||
            entry.operator.toLowerCase().includes(filter.toLowerCase()) ||
            entry.date.includes(filter)
    );

    // Pagination
    const totalPages = Math.ceil(filteredHistory.length / itemsPerPage);
    const paginatedHistory = filteredHistory.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === "next" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des assemblages</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par pièce, opérateur ou date..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau de l'historique */}
            {paginatedHistory.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Pièce</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Quantité</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedHistory.map((entry) => (
                            <tr key={entry.id}>
                                <td className="border border-gray-300 p-2">{entry.date}</td>
                                <td className="border border-gray-300 p-2">{entry.piece}</td>
                                <td className="border border-gray-300 p-2">{entry.operator}</td>
                                <td className="border border-gray-300 p-2">{entry.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun historique trouvé.</p>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
                <button
                    onClick={() => handlePageChange("prev")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === 1}
                >
                    Précédent
                </button>
                <span>
                    Page {currentPage} sur {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange("next")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === totalPages}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default AssemblyHistory;
