import React from "react";
import { useNavigate } from "react-router-dom";
import { exportData } from "../services/exportData";
import ExportTable from "../components/ExportTable";

const ExportList = () => {
    const navigate = useNavigate();

    const handlePrepareExport = () => {
        navigate("/prepare-export");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Exportations</h1>
            <ExportTable exports={exportData} />
            <button
                onClick={handlePrepareExport}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Préparer une exportation
            </button>
        </div>
    );
};

export default ExportList;
