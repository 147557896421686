import React, { useState } from "react";
import { sparePartsData } from "../services/sparePartsData";

const SparePartsManagement = () => {
    const [spareParts, setSpareParts] = useState(sparePartsData);
    const [orderQuantity, setOrderQuantity] = useState({});

    const handleOrderChange = (id, value) => {
        setOrderQuantity((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleOrderSubmit = (id) => {
        const quantity = parseInt(orderQuantity[id], 10);
        if (!quantity || quantity <= 0) {
            alert("Veuillez entrer une quantité valide.");
            return;
        }

        setSpareParts((prev) =>
            prev.map((part) =>
                part.id === id
                    ? { ...part, stock: part.stock + quantity }
                    : part
            )
        );

        alert(`Commande de ${quantity} pièces pour ${spareParts.find((part) => part.id === id).name} passée avec succès !`);
        setOrderQuantity((prev) => ({ ...prev, [id]: "" }));
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des pièces de rechange</h1>

            {/* Tableau des pièces de rechange */}
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Nom</th>
                        <th className="border border-gray-300 p-2">Stock actuel</th>
                        <th className="border border-gray-300 p-2">Seuil critique</th>
                        <th className="border border-gray-300 p-2">Statut</th>
                        <th className="border border-gray-300 p-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {spareParts.map((part) => (
                        <tr key={part.id}>
                            <td className="border border-gray-300 p-2">{part.name}</td>
                            <td className="border border-gray-300 p-2">{part.stock}</td>
                            <td className="border border-gray-300 p-2">{part.criticalThreshold}</td>
                            <td
                                className={`border border-gray-300 p-2 ${part.stock < part.criticalThreshold
                                        ? "text-red-500"
                                        : "text-green-500"
                                    }`}
                            >
                                {part.stock < part.criticalThreshold ? "Sous le seuil critique" : "OK"}
                            </td>
                            <td className="border border-gray-300 p-2">
                                <div className="flex items-center">
                                    <input
                                        type="number"
                                        value={orderQuantity[part.id] || ""}
                                        onChange={(e) => handleOrderChange(part.id, e.target.value)}
                                        placeholder="Qté à commander"
                                        className="border border-gray-300 rounded p-1 w-20 mr-2"
                                    />
                                    <button
                                        onClick={() => handleOrderSubmit(part.id)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Commander
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SparePartsManagement;
