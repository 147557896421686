import React, { useState } from "react";

const AssignAssemblyTask = () => {
    const [form, setForm] = useState({
        description: "",
        operator: "",
        station: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Nouvelle tâche d'assemblage assignée :", form);
        setForm({ description: "", operator: "", station: "" });
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Assigner une tâche d'assemblage</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2 font-semibold">Description :</label>
                    <input
                        type="text"
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Opérateur :</label>
                    <input
                        type="text"
                        name="operator"
                        value={form.operator}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Station :</label>
                    <input
                        type="text"
                        name="station"
                        value={form.station}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Assigner la tâche
                </button>
            </form>
        </div>
    );
};

export default AssignAssemblyTask;
