import React from "react";
import "./Layout.css";

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Textile Manager. Tous droits réservés.</p>
        </footer>
    );
};

export default Footer;
