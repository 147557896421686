export const exportData = [
    {
        id: 1,
        boxNumber: "B001",
        destination: "Paris",
        status: "Prête",
        weight: "12 kg",
        exportedBy: "Alice Johnson",
    },
    {
        id: 2,
        boxNumber: "B002",
        destination: "Londres",
        status: "En attente",
        weight: "15 kg",
        exportedBy: "Bob Smith",
    },
    {
        id: 3,
        boxNumber: "B003",
        destination: "Berlin",
        status: "Terminée",
        weight: "20 kg",
        exportedBy: "Charlie Brown",
    },
];
