import React, { useState } from "react";
import { shippingHistoryData } from "../services/shippingData";

const ShippingHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
        setCurrentPage(1); // Réinitialiser la pagination lors du filtrage
    };

    // Filtrage des données
    const filteredData = shippingHistoryData.filter(
        (shipment) =>
            shipment.id.toString().includes(filter) ||
            shipment.carrier.toLowerCase().includes(filter) ||
            shipment.status.toLowerCase().includes(filter) ||
            shipment.destination.toLowerCase().includes(filter)
    );

    // Pagination
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Exportation des données
    const handleExport = () => {
        const csvContent = `ID,Transporteur,Destination,Statut,Date\n${filteredData
            .map(
                (shipment) =>
                    `${shipment.id},${shipment.carrier},${shipment.destination},${shipment.status},${shipment.date}`
            )
            .join("\n")}`;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "shipping_history.csv");
        link.click();
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des expéditions</h1>

            {/* Barre de recherche */}
            <div className="mb-4 flex justify-between items-center">
                <input
                    type="text"
                    placeholder="Rechercher par ID, transporteur, statut ou destination..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-2/3"
                />
                <button
                    onClick={handleExport}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Exporter CSV
                </button>
            </div>

            {/* Tableau des expéditions */}
            {paginatedData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">ID</th>
                            <th className="border border-gray-300 p-2">Transporteur</th>
                            <th className="border border-gray-300 p-2">Destination</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((shipment) => (
                            <tr key={shipment.id}>
                                <td className="border border-gray-300 p-2">{shipment.id}</td>
                                <td className="border border-gray-300 p-2">{shipment.carrier}</td>
                                <td className="border border-gray-300 p-2">{shipment.destination}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${shipment.status === "Livrée"
                                            ? "text-green-500"
                                            : shipment.status === "Retardée"
                                                ? "text-orange-500"
                                                : "text-red-500"
                                        }`}
                                >
                                    {shipment.status}
                                </td>
                                <td className="border border-gray-300 p-2">{shipment.date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun historique trouvé.</p>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 border ${currentPage === index + 1
                                    ? "bg-blue-500 text-white"
                                    : "bg-white text-blue-500"
                                } hover:bg-blue-700 hover:text-white mx-1`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ShippingHistory;
