import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { equipmentData } from "../services/equipmentData";

const AddOrEditEquipment = () => {
    const { id } = useParams(); // Récupère l'ID du matériel à partir de l'URL
    const navigate = useNavigate();
    const isEditing = Boolean(id); // Vérifie si nous sommes en mode édition
    const [equipment, setEquipment] = useState({
        name: "",
        status: "En service",
        lastUsed: "",
    });

    useEffect(() => {
        if (isEditing) {
            // Charge les données du matériel à modifier
            const existingEquipment = equipmentData.find(
                (item) => item.id === parseInt(id)
            );
            if (existingEquipment) {
                setEquipment(existingEquipment);
            } else {
                alert("Matériel introuvable.");
                navigate("/equipment-list");
            }
        }
    }, [id, isEditing, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEquipment((prevEquipment) => ({
            ...prevEquipment,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!equipment.name.trim()) {
            alert("Le nom du matériel est obligatoire.");
            return;
        }

        if (isEditing) {
            alert(`Le matériel ${equipment.name} a été mis à jour avec succès.`);
            // Ajouter la logique pour mettre à jour le matériel dans les données ou via une API
        } else {
            alert(`Le matériel ${equipment.name} a été ajouté avec succès.`);
            // Ajouter la logique pour sauvegarder le nouveau matériel dans les données ou via une API
        }

        navigate("/equipment-list");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">
                {isEditing ? "Modifier un matériel" : "Ajouter un matériel"}
            </h1>

            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-4">
                {/* Nom du matériel */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Nom du matériel :</label>
                    <input
                        type="text"
                        name="name"
                        value={equipment.name}
                        onChange={handleInputChange}
                        placeholder="Entrez le nom du matériel"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                {/* Statut */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Statut :</label>
                    <select
                        name="status"
                        value={equipment.status}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    >
                        <option value="En service">En service</option>
                        <option value="En maintenance">En maintenance</option>
                        <option value="Hors service">Hors service</option>
                    </select>
                </div>

                {/* Dernière utilisation */}
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Dernière utilisation :</label>
                    <input
                        type="date"
                        name="lastUsed"
                        value={equipment.lastUsed}
                        onChange={handleInputChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>

                {/* Boutons */}
                <div className="flex justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        {isEditing ? "Mettre à jour" : "Ajouter"}
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate("/equipment-list")}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    >
                        Annuler
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddOrEditEquipment;
