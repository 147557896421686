export const corrections = [
    {
        id: 1,
        piece: "Chemise",
        status: "En attente",
        reason: "Couture incorrecte",
        assignedTo: "Alice Johnson",
    },
    {
        id: 2,
        piece: "Pantalon",
        status: "En cours",
        reason: "Tissu déchiré",
        assignedTo: "Bob Smith",
    },
    {
        id: 3,
        piece: "Veste",
        status: "Terminée",
        reason: "Problème de boutons",
        assignedTo: "Charlie Brown",
    },
];
