export const assemblyTasks = [
    {
        id: 1,
        description: "Assemblage de chemises",
        status: "En cours",
        operator: "Alice Johnson",
        station: "Station A",
    },
    {
        id: 2,
        description: "Assemblage de pantalons",
        status: "En attente",
        operator: "Bob Smith",
        station: "Station B",
    },
    {
        id: 3,
        description: "Assemblage de vestes",
        status: "Terminée",
        operator: "Charlie Brown",
        station: "Station C",
    },
];
