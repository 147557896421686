import React from "react";
import { cuttingTasks } from "../services/cuttingTasksData";
import { cuttingMachines } from "../services/cuttingMachinesData";
import { anomalies } from "../services/cuttingAnomaliesData";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const CuttingDashboardPage = () => {
    // KPI Calculations
    const totalTasks = cuttingTasks.length;
    const ongoingTasks = cuttingTasks.filter((task) => task.status === "En cours").length;
    const totalMachines = cuttingMachines.length;
    const machinesInService = cuttingMachines.filter((machine) => machine.status === "En service").length;
    const totalAnomalies = anomalies.length;
    const unresolvedAnomalies = anomalies.filter((anomaly) => anomaly.status === "Signalée").length;

    // Bar Chart Data
    const taskNames = cuttingTasks.map((task) => task.name);
    const taskProgress = cuttingTasks.map((task) =>
        task.status === "Terminée" ? 100 : task.status === "En cours" ? 50 : 0
    );

    const barData = {
        labels: taskNames,
        datasets: [
            {
                label: "Progression des tâches (%)",
                data: taskProgress,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2, // Réduit la hauteur du graphique
        plugins: {
            legend: {
                position: "bottom", // Place la légende en bas
            },
        },
    };

    // Pie Chart Data
    const pieData = {
        labels: ["Machines en service", "Hors service ou en maintenance"],
        datasets: [
            {
                data: [machinesInService, totalMachines - machinesInService],
                backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5, // Réduit la hauteur du graphique
        plugins: {
            legend: {
                position: "bottom", // Place la légende en bas
            },
        },
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord de la découpe</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Tâches totales</h2>
                    <p className="text-3xl font-bold">{totalTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Tâches en cours</h2>
                    <p className="text-3xl font-bold">{ongoingTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Anomalies non résolues</h2>
                    <p className="text-3xl font-bold text-red-500">{unresolvedAnomalies}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Machines totales</h2>
                    <p className="text-3xl font-bold">{totalMachines}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Machines en service</h2>
                    <p className="text-3xl font-bold">{machinesInService}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Anomalies totales</h2>
                    <p className="text-3xl font-bold">{totalAnomalies}</p>
                </div>
            </div>

            {/* Charts Section */}
            <div className="grid grid-cols-2 gap-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Progression des tâches</h2>
                    <div className="chart-container">
                        <Bar data={barData} options={barOptions} />
                    </div>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des machines</h2>
                    <div className="chart-container">
                        <Pie data={pieData} options={pieOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CuttingDashboardPage;
