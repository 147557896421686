import React from "react";
import styles from "./QualityTable.module.css";

const QualityTable = ({ inspections }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Pièce</th>
                <th>Statut</th>
                <th>Inspecteur</th>
                <th>Défauts</th>
            </tr>
        </thead>
        <tbody>
            {inspections.map((inspection) => (
                <tr key={inspection.id}>
                    <td>{inspection.id}</td>
                    <td>{inspection.piece}</td>
                    <td>
                        <span
                            className={
                                inspection.status === "Validée"
                                    ? styles.completed
                                    : inspection.status === "En cours"
                                        ? styles.inProgress
                                        : styles.pending
                            }
                        >
                            {inspection.status}
                        </span>
                    </td>
                    <td>{inspection.inspector}</td>
                    <td>{inspection.defects}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default QualityTable;
