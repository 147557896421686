import React, { useState } from "react";

const ValidatePiece = () => {
    const [form, setForm] = useState({
        piece: "",
        anomalies: "",
        status: "Validée",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Validation mise à jour :", form);
        setForm({ piece: "", anomalies: "", status: "Validée" });
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Valider une pièce</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2 font-semibold">Pièce :</label>
                    <input
                        type="text"
                        name="piece"
                        value={form.piece}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Anomalies :</label>
                    <textarea
                        name="anomalies"
                        value={form.anomalies}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Statut :</label>
                    <select
                        name="status"
                        value={form.status}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    >
                        <option value="Validée">Validée</option>
                        <option value="Rejetée">Rejetée</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Mettre à jour la validation
                </button>
            </form>
        </div>
    );
};

export default ValidatePiece;
