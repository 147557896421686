import React from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const ValidationDashboard = () => {
    // Données pour les graphiques
    const validationStats = {
        totalValidations: 150,
        defectRate: 12, // En pourcentage
        averageValidationTime: 8, // En minutes
        validationsByStatus: {
            validated: 120,
            rejected: 30,
        },
        validationsByOperator: [
            { operator: "Jean Dupont", count: 50 },
            { operator: "Marie Curie", count: 40 },
            { operator: "Paul Martin", count: 60 },
        ],
    };

    const barData = {
        labels: validationStats.validationsByOperator.map((v) => v.operator),
        datasets: [
            {
                label: "Nombre de validations par opérateur",
                data: validationStats.validationsByOperator.map((v) => v.count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const pieData = {
        labels: ["Validées", "Rejetées"],
        datasets: [
            {
                data: [
                    validationStats.validationsByStatus.validated,
                    validationStats.validationsByStatus.rejected,
                ],
                backgroundColor: ["#4caf50", "#f44336"],
            },
        ],
    };

    const lineData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Validations mensuelles",
                data: [10, 20, 15, 30, 25, 40, 35, 50, 45, 60, 70, 80],
                fill: false,
                borderColor: "rgba(75, 192, 192, 1)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des validations</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Total des validations</h2>
                    <p className="text-3xl font-bold">{validationStats.totalValidations}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux de défauts</h2>
                    <p className="text-3xl font-bold text-red-500">{validationStats.defectRate}%</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Temps moyen de validation</h2>
                    <p className="text-3xl font-bold">{validationStats.averageValidationTime} min</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Validations par opérateur</h2>
                    <Bar data={barData} />
                </div>

                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des validations</h2>
                    <Pie data={pieData} />
                </div>
            </div>

            {/* Graphique linéaire */}
            <div className="bg-white shadow-md rounded p-4 mt-6">
                <h2 className="text-lg font-bold mb-4">Tendances mensuelles</h2>
                <Line data={lineData} />
            </div>
        </div>
    );
};

export default ValidationDashboard;
