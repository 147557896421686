export const nonConformingPiecesData = [
    {
        id: 1,
        name: "Pièce A",
        inspector: "Jean Dupont",
        status: "Rejetée",
    },
    {
        id: 2,
        name: "Pièce B",
        inspector: "Marie Curie",
        status: "Rejetée",
    },
    {
        id: 3,
        name: "Pièce C",
        inspector: "Paul Martin",
        status: "Rejetée",
    },
];
