import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { productionSteps } from "../services/productionStepsData";
import { operators } from "../services/operatorData";
import { machines } from "../services/machineData";

const ProductionStepDetailsPage = () => {
    const { id } = useParams(); // Récupère l'ID de l'étape depuis l'URL
    const navigate = useNavigate();

    // Trouve l'étape correspondant à l'ID
    const step = productionSteps.find((step) => step.id === parseInt(id));

    if (!step) {
        return <p className="text-center text-red-500">Étape non trouvée.</p>;
    }

    const operator = operators.find((op) => op.id === step.operatorId);
    const machine = machines.find((machine) => machine.id === step.machineId);

    const handleEdit = () => {
        navigate(`/edit-production-step/${id}`);
    };

    const handleBack = () => {
        navigate("/production-steps");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de l'étape de production</h1>

            {/* Informations générales */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Informations générales</h2>
                <p>
                    <strong>Nom :</strong> {step.name}
                </p>
                <p>
                    <strong>Statut :</strong> {step.status}
                </p>
                <p>
                    <strong>Temps écoulé :</strong> {step.timeElapsed}
                </p>
            </div>

            {/* Opérateur assigné */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Opérateur</h2>
                {operator ? (
                    <p>
                        <strong>Nom :</strong> {operator.name}
                    </p>
                ) : (
                    <p className="text-red-500">Aucun opérateur assigné.</p>
                )}
            </div>

            {/* Machine assignée */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Machine</h2>
                {machine ? (
                    <p>
                        <strong>Nom :</strong> {machine.name}
                    </p>
                ) : (
                    <p className="text-red-500">Aucune machine assignée.</p>
                )}
            </div>

            {/* Actions */}
            <div className="flex justify-between">
                <button
                    onClick={handleEdit}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Modifier
                </button>
                <button
                    onClick={handleBack}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                >
                    Retour
                </button>
            </div>
        </div>
    );
};

export default ProductionStepDetailsPage;
