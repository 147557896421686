export const operators = [
    {
        id: 1,
        name: "Jean Dupont",
        completedTasks: 12,
        defectRate: 8, // Pourcentage de défauts
        lastTask: "Assemblage poches",
    },
    {
        id: 2,
        name: "Marie Curie",
        completedTasks: 15,
        defectRate: 5,
        lastTask: "Couture manches",
    },
    {
        id: 3,
        name: "Paul Martin",
        completedTasks: 8,
        defectRate: 12,
        lastTask: "Découpe tissu A",
    },
];
