import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { shippingTrackingData } from "../services/shippingData";

const ShipmentDetails = () => {
    const { id } = useParams(); // Récupérer l'ID de l'expédition à partir de l'URL
    const navigate = useNavigate();
    const shipment = shippingTrackingData.find(
        (shipment) => shipment.id === parseInt(id)
    );

    if (!shipment) {
        return <p className="text-center text-red-500">Expédition introuvable.</p>;
    }

    const handleMarkAsDelivered = () => {
        alert(`L'expédition ${shipment.id} a été marquée comme livrée.`);
        // Ajouter la logique pour mettre à jour l'état de l'expédition
        navigate("/shipping-tracking"); // Redirection après l'action
    };

    const handleEditShipment = () => {
        alert(`Modification des informations de l'expédition ${shipment.id}.`);
        // Ajouter la logique pour rediriger vers une page de modification ou ouvrir une modal
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de l'expédition : {shipment.id}</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Informations générales</h2>
                <p>
                    <strong>Transporteur :</strong> {shipment.carrier}
                </p>
                <p>
                    <strong>Destination :</strong> {shipment.destination}
                </p>
                <p>
                    <strong>Statut :</strong>{" "}
                    <span
                        className={`${shipment.status === "Livrée"
                                ? "text-green-500"
                                : shipment.status === "Retardée"
                                    ? "text-orange-500"
                                    : "text-red-500"
                            }`}
                    >
                        {shipment.status}
                    </span>
                </p>
                <p>
                    <strong>Dernière mise à jour :</strong> {shipment.lastUpdated}
                </p>
            </div>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Boîtes incluses dans l'expédition</h2>
                <ul className="list-disc ml-6">
                    {/* Simuler les boîtes incluses dans l'expédition */}
                    <li>Boîte A - Pièces : 5</li>
                    <li>Boîte B - Pièces : 3</li>
                    <li>Boîte C - Pièces : 8</li>
                </ul>
            </div>

            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Actions</h2>
                {shipment.status !== "Livrée" && (
                    <button
                        onClick={handleMarkAsDelivered}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mr-4"
                    >
                        Marquer comme livrée
                    </button>
                )}
                <button
                    onClick={handleEditShipment}
                    className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700"
                >
                    Modifier
                </button>
            </div>
        </div>
    );
};

export default ShipmentDetails;
