import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { qualityInspectionData } from "../services/qualityInspectionData";
import { qualityDefectsData } from "../services/qualityDefectsData";

const QualityInspectionDetails = () => {
    const { id } = useParams(); // ID de la pièce
    const navigate = useNavigate();

    const piece = qualityInspectionData.find((item) => item.id === parseInt(id));

    if (!piece) {
        return <p className="text-center text-red-500">Pièce non trouvée.</p>;
    }

    const defects = qualityDefectsData.filter((defect) => defect.pieceId === piece.id);

    const handleMarkConforming = () => {
        alert(`La pièce "${piece.name}" a été marquée comme conforme.`);
        navigate("/quality-inspections");
    };

    const handleReportDefect = () => {
        navigate(`/report-quality-defects/${piece.id}`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de l'inspection qualité</h1>

            {/* Informations générales */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Informations générales</h2>
                <p>
                    <strong>Nom :</strong> {piece.name}
                </p>
                <p>
                    <strong>Inspecteur :</strong> {piece.inspector}
                </p>
                <p>
                    <strong>Statut :</strong> {piece.status}
                </p>
            </div>

            {/* Historique des défauts */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Historique des défauts</h2>
                {defects.length > 0 ? (
                    <ul>
                        {defects.map((defect) => (
                            <li key={defect.id} className="mb-2">
                                <strong>Date :</strong> {defect.date} <br />
                                <strong>Description :</strong> {defect.description}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucun défaut signalé pour cette pièce.</p>
                )}
            </div>

            {/* Actions */}
            <div className="flex justify-between">
                <button
                    onClick={handleMarkConforming}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Marquer conforme
                </button>
                <button
                    onClick={handleReportDefect}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Signaler un défaut
                </button>
            </div>
        </div>
    );
};

export default QualityInspectionDetails;
