import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cuttingTasks } from "../services/cuttingTasksData";

const CreateCuttingTask = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState({
        name: "",
        startDate: "",
        operator: "",
        status: "Planifiée", // Statut par défaut
    });

    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des champs
        if (!form.name || !form.startDate || !form.operator) {
            setError("Tous les champs sont obligatoires.");
            return;
        }

        // Ajout de la nouvelle tâche
        const newTask = {
            id: cuttingTasks.length + 1,
            ...form,
        };
        cuttingTasks.push(newTask);

        console.log("Nouvelle tâche ajoutée :", newTask);

        // Redirection vers la liste des tâches
        navigate("/cutting-tasks");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Créer une nouvelle tâche de découpe</h1>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 bg-white shadow-md rounded p-4">
                <div>
                    <label className="block mb-2 font-semibold">Nom de la tâche :</label>
                    <input
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                        placeholder="Ex: Découpe de tissu A"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Date de début :</label>
                    <input
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Opérateur :</label>
                    <input
                        type="text"
                        name="operator"
                        value={form.operator}
                        onChange={handleChange}
                        placeholder="Nom de l'opérateur"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Statut :</label>
                    <select
                        name="status"
                        value={form.status}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    >
                        <option value="Planifiée">Planifiée</option>
                        <option value="En cours">En cours</option>
                        <option value="Terminée">Terminée</option>
                    </select>
                </div>

                <div className="flex justify-between">
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                    >
                        Ajouter la tâche
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate("/cutting-tasks")}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    >
                        Annuler
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateCuttingTask;
