export const qualityInspectionHistoryData = [
    {
        id: 1,
        date: "2024-11-20",
        pieceName: "Pièce A",
        inspector: "Jean Dupont",
        status: "Conforme",
    },
    {
        id: 2,
        date: "2024-11-21",
        pieceName: "Pièce B",
        inspector: "Marie Curie",
        status: "Non conforme",
    },
    {
        id: 3,
        date: "2024-11-22",
        pieceName: "Pièce C",
        inspector: "Paul Martin",
        status: "Conforme",
    },
];
