import React from "react";
import { materials } from "../services/materialsData";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const MaterialsDashboard = () => {
    const totalMaterials = materials.length;
    const totalQuantity = materials.reduce((sum, mat) => sum + mat.quantity, 0);
    const criticalMaterials = materials.filter((mat) => mat.quantity < 100).length;

    const materialNames = materials.map((mat) => mat.name);
    const materialQuantities = materials.map((mat) => mat.quantity);

    const barData = {
        labels: materialNames,
        datasets: [
            {
                label: "Quantité par matière",
                data: materialQuantities,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const pieData = {
        labels: ["Critique", "Suffisant"],
        datasets: [
            {
                data: [criticalMaterials, totalMaterials - criticalMaterials],
                backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)"],
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2, // Réduit l'encombrement des graphiques
        plugins: {
            legend: {
                position: "bottom", // Réduit l'espace utilisé par la légende
            },
        },
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des matières premières</h1>

            {/* Indicateurs clés */}
            <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Total des matières</h2>
                    <p className="text-3xl font-bold">{totalMaterials}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Quantité totale</h2>
                    <p className="text-3xl font-bold">{totalQuantity} kg</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Matières critiques</h2>
                    <p className="text-3xl font-bold text-red-500">{criticalMaterials}</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Quantité par matière</h2>
                    <div className="graph-container">
                        <Bar data={barData} options={chartOptions} />
                    </div>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des matières</h2>
                    <div className="graph-container">
                        <Pie data={pieData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaterialsDashboard;
