import React, { useState } from "react";
import { shippingAnomaliesData } from "../services/shippingData";

const ShippingAnomalies = () => {
    const [anomalies, setAnomalies] = useState(shippingAnomaliesData);
    const [newAnomaly, setNewAnomaly] = useState({
        id: null,
        description: "",
        status: "En attente",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAnomaly((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!newAnomaly.description.trim()) {
            alert("Veuillez fournir une description pour l'anomalie.");
            return;
        }

        setAnomalies((prevAnomalies) => [
            ...prevAnomalies,
            { ...newAnomaly, id: prevAnomalies.length + 1 },
        ]);

        setNewAnomaly({ id: null, description: "", status: "En attente" });
        alert("Anomalie signalée avec succès !");
    };

    const handleMarkAsResolved = (id) => {
        setAnomalies((prevAnomalies) =>
            prevAnomalies.map((anomaly) =>
                anomaly.id === id ? { ...anomaly, status: "Résolue" } : anomaly
            )
        );
        alert(`Anomalie ${id} marquée comme résolue.`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des anomalies d’expédition</h1>

            {/* Formulaire pour signaler une nouvelle anomalie */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Signaler une anomalie</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Description :</label>
                        <textarea
                            name="description"
                            value={newAnomaly.description}
                            onChange={handleInputChange}
                            placeholder="Décrivez l'anomalie"
                            className="border border-gray-300 rounded p-2 w-full"
                            rows="3"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Signaler
                    </button>
                </form>
            </div>

            {/* Tableau des anomalies */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Liste des anomalies</h2>
                {anomalies.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">ID</th>
                                <th className="border border-gray-300 p-2">Description</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                                <th className="border border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {anomalies.map((anomaly) => (
                                <tr key={anomaly.id}>
                                    <td className="border border-gray-300 p-2">{anomaly.id}</td>
                                    <td className="border border-gray-300 p-2">{anomaly.description}</td>
                                    <td
                                        className={`border border-gray-300 p-2 ${anomaly.status === "Résolue" ? "text-green-500" : "text-red-500"
                                            }`}
                                    >
                                        {anomaly.status}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {anomaly.status !== "Résolue" && (
                                            <button
                                                onClick={() => handleMarkAsResolved(anomaly.id)}
                                                className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                            >
                                                Marquer comme résolue
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune anomalie signalée.</p>
                )}
            </div>
        </div>
    );
};

export default ShippingAnomalies;
