export const correctionsData = [
    {
        id: 1,
        pieceName: "Pièce A",
        operator: "Jean Dupont",
        correctionStep: "Couture",
        status: "En cours",
    },
    {
        id: 2,
        pieceName: "Pièce B",
        operator: "Marie Curie",
        correctionStep: "Découpe",
        status: "Corrigé",
    },
    {
        id: 3,
        pieceName: "Pièce C",
        operator: "Paul Martin",
        correctionStep: "Assemblage",
        status: "Rejeté à nouveau",
    },
];
