export const packagingData = [
    {
        id: 1,
        name: "Boîte A",
        type: "Boîte",
        status: "Prête à expédier",
    },
    {
        id: 2,
        name: "Boîte B",
        type: "Boîte",
        status: "En attente",
    },
    {
        id: 3,
        name: "Pièce A",
        type: "Pièce",
        status: "Prête à emballer",
    },
    {
        id: 4,
        name: "Pièce B",
        type: "Pièce",
        status: "Prête à emballer",
    },
];

export const piecesData = [
    { id: 1, name: "Pièce A", status: "Prête à emballer" },
    { id: 2, name: "Pièce B", status: "Prête à emballer" },
    { id: 3, name: "Pièce C", status: "Prête à emballer" },
    { id: 4, name: "Pièce D", status: "En attente" },
];

export const packagedBoxesData = [
    {
        id: 1,
        name: "Boîte A",
        status: "Prête à expédier",
        contents: ["Pièce A", "Pièce B", "Pièce C"],
    },
    {
        id: 2,
        name: "Boîte B",
        status: "En attente",
        contents: ["Pièce D", "Pièce E"],
    },
    {
        id: 3,
        name: "Boîte C",
        status: "En attente",
        contents: ["Pièce F", "Pièce G", "Pièce H"],
    },
    {
        id: 4,
        name: "Boîte D",
        status: "En attente",
        contents: ["Pièce H", "Pièce I"],
    },
];

export const packagingResourcesData = [
    {
        id: 1,
        name: "Cartons",
        type: "Boîte",
        quantity: 50,
        criticalThreshold: 30,
    },
    {
        id: 2,
        name: "Étiquettes",
        type: "Étiquette",
        quantity: 20,
        criticalThreshold: 25,
    },
    {
        id: 3,
        name: "Ruban adhésif",
        type: "Accessoire",
        quantity: 100,
        criticalThreshold: 50,
    },
];

export const anomaliesData = [
    {
        id: 1,
        description: "Erreur dans le poids de la boîte",
        status: "En attente",
    },
    {
        id: 2,
        description: "Carton endommagé pendant l’emballage",
        status: "En attente",
    },
];

export const packagingHistoryData = [
    {
        id: 1,
        boxName: "Boîte A",
        date: "2024-11-20",
        status: "Expédiée",
        contents: ["Pièce A", "Pièce B", "Pièce C"],
    },
    {
        id: 2,
        boxName: "Boîte B",
        date: "2024-11-19",
        status: "En attente",
        contents: ["Pièce D", "Pièce E"],
    },
    {
        id: 3,
        boxName: "Boîte C",
        date: "2024-11-18",
        status: "Expédiée",
        contents: ["Pièce F", "Pièce G"],
    },
    {
        id: 4,
        boxName: "Boîte D",
        date: "2024-11-17",
        status: "En attente",
        contents: ["Pièce H", "Pièce I"],
    },
];

