// Page for detailed view of a Materials item
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { materials } from "../services/materialsData";
import { movements } from "../services/movementsData";

const MaterialDetails = () => {
    const { id } = useParams(); // Récupère l'ID de la matière depuis l'URL
    const navigate = useNavigate();

    // Trouve la matière correspondante à l'ID
    const material = materials.find((mat) => mat.id === parseInt(id));

    if (!material) {
        return <p className="text-center text-red-500">Matière non trouvée.</p>;
    }

    // Récupère les mouvements associés à cette matière
    const materialMovements = movements.filter(
        (move) => move.materialId === material.id
    );

    const handleEdit = () => {
        navigate(`/update-stock/${id}`);
    };

    const handleDelete = () => {
        const confirmed = window.confirm(
            "Êtes-vous sûr de vouloir supprimer cette matière ?"
        );
        if (confirmed) {
            console.log("Matière supprimée :", material);
            navigate("/");
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Détails de la matière</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Informations générales</h2>
                <p><strong>Nom :</strong> {material.name}</p>
                <p><strong>Quantité :</strong> {material.quantity} {material.unit}</p>
                <p><strong>Fournisseur :</strong> {material.supplier}</p>
                <p><strong>Dernière mise à jour :</strong> {material.lastUpdated}</p>
                <div className="mt-4">
                    <button
                        onClick={handleEdit}
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-700"
                    >
                        Modifier
                    </button>
                    <button
                        onClick={handleDelete}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    >
                        Supprimer
                    </button>
                </div>
            </div>

            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Historique des mouvements</h2>
                {materialMovements.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Date</th>
                                <th className="border border-gray-300 p-2">Type</th>
                                <th className="border border-gray-300 p-2">Quantité</th>
                                <th className="border border-gray-300 p-2">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materialMovements.map((movement) => (
                                <tr key={movement.id}>
                                    <td className="border border-gray-300 p-2">
                                        {movement.date}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {movement.type}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {movement.quantity} {material.unit}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {movement.description}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Aucun mouvement enregistré pour cette matière.</p>
                )}
            </div>
        </div>
    );
};

export default MaterialDetails;
