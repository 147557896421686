import React, { useState } from "react";
import { breakdownData } from "../services/breakdownData";

const EquipmentBreakdowns = () => {
    const [breakdowns, setBreakdowns] = useState(breakdownData);
    const [newBreakdown, setNewBreakdown] = useState({
        id: null,
        equipment: "",
        description: "",
        status: "En attente",
        dateReported: new Date().toISOString().split("T")[0],
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBreakdown((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleReportBreakdown = (e) => {
        e.preventDefault();

        if (!newBreakdown.equipment.trim() || !newBreakdown.description.trim()) {
            alert("Veuillez remplir tous les champs.");
            return;
        }

        setBreakdowns((prev) => [
            ...prev,
            { ...newBreakdown, id: prev.length + 1 },
        ]);

        alert("Panne signalée avec succès !");
        setNewBreakdown({
            id: null,
            equipment: "",
            description: "",
            status: "En attente",
            dateReported: new Date().toISOString().split("T")[0],
        });
    };

    const handleMarkResolved = (id) => {
        const confirmed = window.confirm(
            "Êtes-vous sûr de vouloir marquer cette panne comme résolue ?"
        );
        if (confirmed) {
            setBreakdowns((prev) =>
                prev.map((breakdown) =>
                    breakdown.id === id ? { ...breakdown, status: "Résolue" } : breakdown
                )
            );
            alert(`La panne ${id} a été marquée comme résolue.`);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des pannes</h1>

            {/* Formulaire pour signaler une panne */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Signaler une panne</h2>
                <form onSubmit={handleReportBreakdown}>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Matériel :</label>
                        <input
                            type="text"
                            name="equipment"
                            value={newBreakdown.equipment}
                            onChange={handleInputChange}
                            placeholder="Nom du matériel"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Description :</label>
                        <textarea
                            name="description"
                            value={newBreakdown.description}
                            onChange={handleInputChange}
                            placeholder="Description de la panne"
                            className="border border-gray-300 rounded p-2 w-full"
                            rows="3"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Signaler
                    </button>
                </form>
            </div>

            {/* Tableau des pannes */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Liste des pannes</h2>
                {breakdowns.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">ID</th>
                                <th className="border border-gray-300 p-2">Matériel</th>
                                <th className="border border-gray-300 p-2">Description</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                                <th className="border border-gray-300 p-2">Date signalée</th>
                                <th className="border border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {breakdowns.map((breakdown) => (
                                <tr key={breakdown.id}>
                                    <td className="border border-gray-300 p-2">{breakdown.id}</td>
                                    <td className="border border-gray-300 p-2">{breakdown.equipment}</td>
                                    <td className="border border-gray-300 p-2">{breakdown.description}</td>
                                    <td
                                        className={`border border-gray-300 p-2 ${breakdown.status === "Résolue"
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }`}
                                    >
                                        {breakdown.status}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {breakdown.dateReported}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {breakdown.status !== "Résolue" && (
                                            <button
                                                onClick={() => handleMarkResolved(breakdown.id)}
                                                className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                            >
                                                Marquer comme résolue
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune panne signalée.</p>
                )}
            </div>
        </div>
    );
};

export default EquipmentBreakdowns;
