export const replacementsData = [
    {
        id: 1,
        pieceName: "Pièce A",
        reason: "Impossible de corriger la couture",
        status: "En attente",
    },
    {
        id: 2,
        pieceName: "Pièce B",
        reason: "Défaut structurel dans le tissu",
        status: "En attente",
    },
    {
        id: 3,
        pieceName: "Pièce C",
        reason: "Problème de découpe irrécupérable",
        status: "En attente",
    },
];
