import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const CorrectionsDashboard = () => {
    // Données pour les KPI
    const stats = {
        totalCorrections: 120,
        successRate: 85, // En pourcentage
        avgTimePerCorrection: 15, // En minutes
        correctionsByStatus: {
            validated: 102,
            rejected: 10,
            inProgress: 8,
        },
        typesDistribution: [
            { type: "Correction", count: 90 },
            { type: "Remplacement", count: 30 },
        ],
    };

    // Données pour les graphiques
    const pieData = {
        labels: ["Validées", "Rejetées", "En cours"],
        datasets: [
            {
                data: [
                    stats.correctionsByStatus.validated,
                    stats.correctionsByStatus.rejected,
                    stats.correctionsByStatus.inProgress,
                ],
                backgroundColor: ["#4caf50", "#f44336", "#2196f3"],
            },
        ],
    };

    const barData = {
        labels: stats.typesDistribution.map((item) => item.type),
        datasets: [
            {
                label: "Nombre par type",
                data: stats.typesDistribution.map((item) => item.count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des corrections et remplacements</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Total des corrections</h2>
                    <p className="text-3xl font-bold">{stats.totalCorrections}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux de succès</h2>
                    <p className="text-3xl font-bold text-green-500">{stats.successRate}%</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Temps moyen par correction</h2>
                    <p className="text-3xl font-bold">{stats.avgTimePerCorrection} min</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des statuts</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition par type</h2>
                    <Bar data={barData} />
                </div>
            </div>
        </div>
    );
};

export default CorrectionsDashboard;
