export const materials = [
    {
        id: 1,
        name: "Coton",
        quantity: 80, // Sous le seuil critique
        unit: "kg",
        supplier: "Supplier A",
        lastUpdated: "2024-11-20",
    },
    {
        id: 2,
        name: "Polyester",
        quantity: 300,
        unit: "kg",
        supplier: "Supplier B",
        lastUpdated: "2024-11-18",
    },
    {
        id: 3,
        name: "Laine",
        quantity: 50, // Sous le seuil critique
        unit: "kg",
        supplier: "Supplier C",
        lastUpdated: "2024-11-19",
    },
];
