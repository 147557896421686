export const defectReportsData = [
    {
        id: 1,
        pieceName: "Pièce A",
        anomalyType: "Couture incorrecte",
        description: "Défaut au niveau de la couture gauche.",
        status: "En attente",
        date: "2024-11-20",
    },
    {
        id: 2,
        pieceName: "Pièce B",
        anomalyType: "Coupe incorrecte",
        description: "Tissu coupé de manière asymétrique.",
        status: "En attente",
        date: "2024-11-21",
    },
];
