export const validationData = [
    {
        id: 1,
        name: "Pièce A",
        piece: "Chemise",
        status: "En attente",
        inspector: "Alice Johnson",
        anomalies: "Aucune",
        operator: "Jean Dupont",
    },
    {
        id: 2,
        piece: "Pantalon",
        name: "Pièce B",
        status: "En cours",
        inspector: "Bob Smith",
        anomalies: "Coupe incorrecte",
        operator: "Marie Curie",
    },
    {
        id: 3,
        name: "Pièce C",
        piece: "Veste",
        status: "Validée",
        inspector: "Charlie Brown",
        anomalies: "Aucune",
        operator: "Paul Martin",
    },
    {
        id: 4,
        name: "Pièce C",
        piece: "Piece",
        status: "Rejetée",
        operator: "Paul Martin",
        inspector: "Charlie Brown",
        anomalies: "Aucune",
    },
];

export const validations = [
    {
        id: 1,
        name: "Pièce A",
        piece: "Chemise",
        status: "En attente",
        inspector: "Alice Johnson",
        anomalies: "Aucune",
        operator: "Jean Dupont",
    },
    {
        id: 2,
        piece: "Pantalon",
        name: "Pièce B",
        status: "En cours",
        inspector: "Bob Smith",
        anomalies: "Coupe incorrecte",
        operator: "Marie Curie",
    },
    {
        id: 3,
        name: "Pièce C",
        piece: "Veste",
        status: "Validée",
        inspector: "Charlie Brown",
        anomalies: "Aucune",
        operator: "Paul Martin",
    },
    {
        id: 4,
        name: "Pièce C",
        piece: "Piece",
        status: "Rejetée",
        operator: "Paul Martin",
        inspector: "Charlie Brown",
        anomalies: "Aucune",
    },
];
