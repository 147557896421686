import React from "react";
import { useParams } from "react-router-dom";
import { packagedBoxesData } from "../services/packagingData";

const PackagedBoxDetails = () => {
    const { id } = useParams(); // Récupérer l'ID de la boîte à partir des paramètres d'URL
    const box = packagedBoxesData.find((box) => box.id === parseInt(id));

    if (!box) {
        return <p className="text-center text-red-500">Boîte introuvable.</p>;
    }

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de la boîte : {box.name}</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Informations générales</h2>
                <p>
                    <strong>Nom de la boîte :</strong> {box.name}
                </p>
                <p>
                    <strong>Statut :</strong>{" "}
                    <span
                        className={`${box.status === "Prête à expédier"
                                ? "text-green-500"
                                : "text-orange-500"
                            }`}
                    >
                        {box.status}
                    </span>
                </p>
            </div>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Contenu de la boîte</h2>
                {box.contents.length > 0 ? (
                    <ul className="list-disc ml-6">
                        {box.contents.map((piece, index) => (
                            <li key={index}>{piece}</li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucune pièce dans cette boîte.</p>
                )}
            </div>

            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Actions</h2>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mr-4"
                    onClick={() => alert(`Commande pour la boîte ${box.name} générée.`)}
                >
                    Générer une commande
                </button>
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    onClick={() => alert(`Boîte ${box.name} marquée comme expédiée.`)}
                >
                    Marquer comme expédiée
                </button>
            </div>
        </div>
    );
};

export default PackagedBoxDetails;
