export const productionSteps = [
    {
        id: 1,
        stepName: "Couture",
        status: "En cours",
        operator: "Emily Watson",
        machine: "Machine 1",
        piecesProcessed: 50,
    },
    {
        id: 2,
        stepName: "Assemblage final",
        status: "En attente",
        operator: "Jack Brown",
        machine: "Machine 2",
        piecesProcessed: 30,
    },
    {
        id: 3,
        stepName: "Contrôle visuel",
        status: "Terminée",
        operator: "Sophia Green",
        machine: "Machine 3",
        piecesProcessed: 75,
    },
];
