import React, { useState } from "react";
import { piecesData } from "../services/packagingData";

const PackageBox = () => {
    const [selectedPieces, setSelectedPieces] = useState([]);
    const [boxName, setBoxName] = useState("");

    const handlePieceSelection = (pieceId) => {
        setSelectedPieces((prevSelected) =>
            prevSelected.includes(pieceId)
                ? prevSelected.filter((id) => id !== pieceId)
                : [...prevSelected, pieceId]
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!boxName || selectedPieces.length === 0) {
            alert("Veuillez saisir un nom pour la boîte et sélectionner au moins une pièce.");
            return;
        }

        alert(`Boîte "${boxName}" créée avec succès ! Contenu : ${selectedPieces.join(", ")}`);
        // Ajouter la logique pour sauvegarder la boîte et ses pièces
        setBoxName("");
        setSelectedPieces([]);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Créer une boîte</h1>

            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-4">
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Nom de la boîte :</label>
                    <input
                        type="text"
                        value={boxName}
                        onChange={(e) => setBoxName(e.target.value)}
                        placeholder="Nom de la boîte"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-2">Sélectionnez les pièces à inclure :</label>
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Sélectionner</th>
                                <th className="border border-gray-300 p-2">Nom</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                            </tr>
                        </thead>
                        <tbody>
                            {piecesData.map((piece) => (
                                <tr key={piece.id}>
                                    <td className="border border-gray-300 p-2 text-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedPieces.includes(piece.id)}
                                            onChange={() => handlePieceSelection(piece.id)}
                                        />
                                    </td>
                                    <td className="border border-gray-300 p-2">{piece.name}</td>
                                    <td className="border border-gray-300 p-2">{piece.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Enregistrer la boîte
                </button>
            </form>
        </div>
    );
};

export default PackageBox;
