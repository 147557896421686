import React, { useState } from "react";
import { correctionsData } from "../services/correctionsData";

const PlanCorrections = () => {
    const [filter, setFilter] = useState("");
    const [corrections, setCorrections] = useState(correctionsData);
    const [form, setForm] = useState({
        pieceName: "",
        assignedOperator: "",
        correctionStep: "",
    });

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleAssignTask = (e) => {
        e.preventDefault();

        if (!form.pieceName || !form.assignedOperator || !form.correctionStep) {
            alert("Veuillez remplir tous les champs.");
            return;
        }

        setCorrections(
            corrections.map((correction) =>
                correction.pieceName.toLowerCase() === form.pieceName.toLowerCase()
                    ? { ...correction, assignedOperator: form.assignedOperator, correctionStep: form.correctionStep, status: "En cours" }
                    : correction
            )
        );

        setForm({
            pieceName: "",
            assignedOperator: "",
            correctionStep: "",
        });

        alert("Tâche de correction assignée avec succès !");
    };

    const filteredCorrections = corrections.filter(
        (correction) =>
            correction.pieceName.toLowerCase().includes(filter) ||
            correction.status.toLowerCase().includes(filter) ||
            correction.anomalyType.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Planification des corrections</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom de pièce, statut ou type d’anomalie..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Formulaire pour assigner des tâches */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Assigner une tâche de correction</h2>
                <form onSubmit={handleAssignTask}>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Nom de la pièce :</label>
                        <input
                            type="text"
                            name="pieceName"
                            value={form.pieceName}
                            onChange={handleInputChange}
                            placeholder="Nom de la pièce"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Opérateur assigné :</label>
                        <input
                            type="text"
                            name="assignedOperator"
                            value={form.assignedOperator}
                            onChange={handleInputChange}
                            placeholder="Nom de l'opérateur"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Étape de correction :</label>
                        <input
                            type="text"
                            name="correctionStep"
                            value={form.correctionStep}
                            onChange={handleInputChange}
                            placeholder="Étape de correction"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Assigner la tâche
                    </button>
                </form>
            </div>

            {/* Tableau des corrections */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Liste des corrections</h2>
                {filteredCorrections.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Nom de la pièce</th>
                                <th className="border border-gray-300 p-2">Type d’anomalie</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                                <th className="border border-gray-300 p-2">Opérateur assigné</th>
                                <th className="border border-gray-300 p-2">Étape de correction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCorrections.map((correction) => (
                                <tr key={correction.id}>
                                    <td className="border border-gray-300 p-2">{correction.pieceName}</td>
                                    <td className="border border-gray-300 p-2">{correction.anomalyType}</td>
                                    <td
                                        className={`border border-gray-300 p-2 ${correction.status === "En cours" ? "text-blue-500" : "text-orange-500"
                                            }`}
                                    >
                                        {correction.status}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {correction.assignedOperator || "Non assigné"}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {correction.correctionStep || "Non assigné"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune correction à afficher.</p>
                )}
            </div>
        </div>
    );
};

export default PlanCorrections;
