import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cuttingTasks } from "../services/cuttingTasksData";

const CuttingTasksList = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [tasks, setTasks] = useState(cuttingTasks);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleDelete = (id) => {
        const confirmed = window.confirm(
            "Êtes-vous sûr de vouloir supprimer cette tâche ?"
        );
        if (confirmed) {
            setTasks(tasks.filter((task) => task.id !== id));
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-cutting-task/${id}`);
    };

    const handleAddTask = () => {
        navigate("/create-cutting-task");
    };

    const filteredTasks = tasks.filter((task) =>
        task.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des tâches de découpe</h1>

            <div className="mb-4 flex justify-between">
                <input
                    type="text"
                    placeholder="Rechercher une tâche..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-1/2"
                />
                <button
                    onClick={handleAddTask}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Ajouter une tâche
                </button>
            </div>

            {filteredTasks.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Date de début</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTasks.map((task) => (
                            <tr key={task.id}>
                                <td className="border border-gray-300 p-2">{task.name}</td>
                                <td className="border border-gray-300 p-2">{task.startDate}</td>
                                <td className="border border-gray-300 p-2">{task.operator}</td>
                                <td className="border border-gray-300 p-2">{task.status}</td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleEdit(task.id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                    >
                                        Modifier
                                    </button>
                                    <button
                                        onClick={() => handleDelete(task.id)}
                                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        Supprimer
                                    </button>
                                    <button
                                        onClick={() => navigate("/create-cutting-task")}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                    >
                                        Ajouter une tâche
                                    </button>
                                    <button
                                        onClick={() => navigate(`/cutting-task/${task.id}`)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Voir détails
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune tâche trouvée.</p>
            )}
        </div>
    );
};

export default CuttingTasksList;
