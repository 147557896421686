import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import MaterialsList from "./modules/Materials/pages/MaterialsList";
import AddMaterial from "./modules/Materials/pages/AddMaterials";
import ProductionList from "./modules/Production/pages/ProductionList";
import AssignTask from "./modules/Production/pages/AssignTask";
import AssemblyList from "./modules/Assembly/pages/AssemblyList";
import AssignAssemblyTask from "./modules/Assembly/pages/AssignAssemblyTask";
import StepsList from "./modules/ProductionSteps/pages/StepsList";
import AssignStepTask from "./modules/ProductionSteps/pages/AssignStepTask";
import ValidationList from "./modules/InternalValidation/pages/ValidationList";
import ValidatePiece from "./modules/InternalValidation/pages/ValidatePiece";
import QualityList from "./modules/QualityControl/pages/QualityList";
import InspectPiece from "./modules/QualityControl/pages/InspectPiece";
import CorrectionList from "./modules/Correction/pages/CorrectionList";
import CorrectPiece from "./modules/Correction/pages/CorrectPiece";
import PackagingList from "./modules/Packaging/pages/PackagingList";
import PackagePiece from "./modules/Packaging/pages/PackagePiece";
import ExportList from "./modules/Export/pages/ExportList";
import PrepareExport from "./modules/Export/pages/PrepareExport";
import EquipmentList from "./modules/EquipmentManagement/pages/EquipmentList";
import ScheduleMaintenance from "./modules/EquipmentManagement/pages/ScheduleMaintenance";
import MaterialDetails from "./modules/Materials/pages/MaterialsDetails";
import UpdateStock from "./modules/Materials/pages/UpdateStock";
import CriticalThresholds from "./modules/Materials/pages/CriticalThresholds";
import Suppliers from "./modules/Materials/pages/Suppliers";
import MovementHistory from "./modules/Materials/pages/MovementHistory";
import MaterialsDashboard from "./modules/Materials/components/MaterialDashboard";
import CuttingTasksList from "./modules/Cutting/pages/CuttingTasksList";
import CreateCuttingTask from "./modules/Cutting/pages/CreateCuttingTask";
import CuttingTaskDetailsPage from "./modules/Cutting/pages/CuttingTaskDetailsPage";
import MachineManagement from "./modules/Cutting/pages/MachineManagement";
import ManageAnomalies from "./modules/Cutting/pages/ManageAnomalies";
import CuttingHistory from "./modules/Cutting/pages/CuttingHistory";
import ResourceAllocation from "./modules/Cutting/pages/ResourceAllocation";
import CuttingDashboardPage from "./modules/Cutting/pages/CuttingDashboardPage";
import AssemblyTasksList from "./modules/Assembly/pages/AssemblyTasksList";
import CreateAssemblyTask from "./modules/Assembly/pages/CreateAssemblyTask";
import AssemblyTaskDetailsPage from "./modules/Assembly/pages/AssemblyTaskDetailsPage";
import IngredientUsage from "./modules/Assembly/pages/IngredientUsage";
import AssemblyHistory from "./modules/Assembly/pages/AssemblyHistory";
import ManageAssemblyAnomalies from "./modules/Assembly/pages/ManageAssemblyAnomalies";
import AssemblyDashboardPage from "./modules/Assembly/pages/AssemblyDashboardPage";
import ProductionTaskList from "./modules/Production/pages/ProductionTaskList";
import TaskAssignment from "./modules/Production/pages/TaskAssignment";
import RealTimeProduction from "./modules/Production/pages/RealTimeProduction";
import ProductionHistory from "./modules/Production/pages/ProductionHistory";
import ProductionDashboardPage from "./modules/Production/pages/ProductionDashboardPage";
import ProductionStepDetailsPage from "./modules/Production/pages/ProductionStepDetailsPage";
import ManageProductionAnomalies from "./modules/Production/pages/ManageProductionAnomalies";
import MachineTracking from "./modules/Production/pages/MachineTracking";
import OperatorTracking from "./modules/Production/pages/OperatorTracking";
import InternalValidationList from "./modules/InternalValidation/pages/InternalValidationList";
import ValidationDetails from "./modules/InternalValidation/pages/ValidationDetails";
import ReportDefects from "./modules/InternalValidation/pages/ReportDefects";
import BatchValidation from "./modules/InternalValidation/pages/BatchValidation";
import ValidationDashboard from "./modules/InternalValidation/pages/ValidationDashboard";
import ValidationHistory from "./modules/InternalValidation/pages/ValidationHistory";
import RejectedPieces from "./modules/InternalValidation/pages/RejectedPieces";
import DefectsReport from "./modules/InternalValidation/pages/DefectsReport";
import QualityInspectionList from "./modules/QualityControl/pages/QualityInspectionList";
import QualityInspectionDetails from "./modules/QualityControl/pages/QualityInspectionDetails";
import ReportQualityDefects from "./modules/QualityControl/pages/ReportQualityDefects";
import NonConformingPieces from "./modules/QualityControl/pages/NonConformingPieces";
import ValidationCorrections from "./modules/QualityControl/pages/ValidationCorrections";
import QualityControlDashboard from "./modules/QualityControl/pages/QualityControlDashboard";
import QualityControlHistory from "./modules/QualityControl/pages/QualityControlHistory";
import QualityDefectsReport from "./modules/QualityControl/pages/QualityDefectsReport";
import RejectedPiecesList from "./modules/RejectedPieces/pages/RejectedPiecesList";
import ReportDefectsForCorrection from "./modules/RejectedPieces/pages/ReportDefectsForCorrection";
import PlanCorrections from "./modules/RejectedPieces/pages/PlanCorrections";
import CorrectionsTracking from "./modules/RejectedPieces/pages/CorrectionsTracking";
import ReplacementsManagement from "./modules/RejectedPieces/pages/ReplacementsManagement";
import CorrectionsValidation from "./modules/RejectedPieces/pages/CorrectionsValidation";
import CorrectionsHistory from "./modules/RejectedPieces/pages/CorrectionsHistory";
import CorrectionsDashboard from "./modules/RejectedPieces/pages/CorrectionsDashboard";
import PackageBox from "./modules/Packaging/pages/PackageBox";
import PackagedBoxesTracking from "./modules/Packaging/pages/PackagedBoxesTracking";
import PackagedBoxDetails from "./modules/Packaging/pages/PackagedBoxDetails";
import PackagingResourcesTracking from "./modules/Packaging/pages/PackagingResourcesTracking";
import PackagingAnomalies from "./modules/Packaging/pages/PackagingAnomalies";
import PackagingHistory from "./modules/Packaging/pages/PackagingHistory";
import PackagingDashboard from "./modules/Packaging/pages/PackagingDashboard";
import LabelAndDocumentGeneration from "./modules/Packaging/pages/LabelAndDocumentGeneration";
import ShippingList from "./modules/Shipping/pages/ShippingList";
import PrepareShipment from "./modules/Shipping/pages/PrepareShipment";
import ShippingTracking from "./modules/Shipping/pages/ShippingTracking";
import ShipmentDetails from "./modules/Shipping/pages/ShipmentDetails";
import ShippingAnomalies from "./modules/Shipping/pages/ShippingAnomalies";
import ShippingHistory from "./modules/Shipping/pages/ShippingHistory";
import ShippingDashboard from "./modules/Shipping/pages/ShippingDashboard";
import GenerateShippingDocuments from "./modules/Shipping/pages/GenerateShippingDocuments";
import AddOrEditEquipment from "./modules/EquipmentManagement/pages/AddOrEditEquipment";
import RealTimeEquipment from "./modules/EquipmentManagement/pages/RealTimeEquipment";
import MaintenancePlanning from "./modules/EquipmentManagement/pages/MaintenancePlanning";
import MaintenanceHistory from "./modules/EquipmentManagement/pages/MaintenanceHistory";
import EquipmentBreakdowns from "./modules/EquipmentManagement/pages/EquipmentBreakdowns";
import EquipmentDashboard from "./modules/EquipmentManagement/pages/EquipmentDashboard";
import EquipmentLifecycle from "./modules/EquipmentManagement/pages/EquipmentLifecycle";
import SparePartsManagement from "./modules/EquipmentManagement/pages/SparePartsManagement";

const AppLayout = ({ children }) => (
    <div className="app-layout">
        <Navbar />
        <div className="content">
            <main>{children}</main>
        </div>
        <Footer />
    </div>
);

const App = () => (
    <Router>
        <AppLayout>
            <Routes>
                <Route path="/" element={<MaterialsList />} />
                <Route path="/add-material" element={<AddMaterial />} />
                <Route path="/production" element={<ProductionList />} />
                <Route path="/assign-task" element={<AssignTask />} />
                <Route path="/assembly" element={<AssemblyList />} />
                <Route path="/assign-assembly-task" element={<AssignAssemblyTask />} />
                <Route path="/production-steps" element={<StepsList />} />
                <Route path="/assign-step-task" element={<AssignStepTask />} />
                {/* <Route path="/internal-validations" element={<ValidationList />} /> */}
                <Route path="/validate-piece" element={<ValidatePiece />} />
                <Route path="/quality-control" element={<QualityList />} />
                <Route path="/inspect-piece" element={<InspectPiece />} />
                <Route path="/corrections" element={<CorrectionList />} />
                <Route path="/correct-piece" element={<CorrectPiece />} />
                {/* <Route path="/packaging" element={<PackagingList />} /> */}
                {/* <Route path="/package-piece" element={<PackagePiece />} /> */}
                <Route path="/exports" element={<ExportList />} />
                <Route path="/prepare-export" element={<PrepareExport />} />
                {/* <Route path="/equipment-management" element={<EquipmentList />} /> */}
                <Route path="/schedule-maintenance" element={<ScheduleMaintenance />} />
                <Route path="/material/:id" element={<MaterialDetails />} />
                <Route path="/update-stock/:id" element={<UpdateStock />} />
                <Route path="/critical-thresholds" element={<CriticalThresholds />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/movement-history" element={<MovementHistory />} />
                <Route path="/materials-dashboard" element={<MaterialsDashboard />} />
                <Route path="/cutting-tasks" element={<CuttingTasksList />} />
                <Route path="/create-cutting-task" element={<CreateCuttingTask />} />
                <Route path="/cutting-task/:id" element={<CuttingTaskDetailsPage />} />
                <Route path="/machine-management" element={<MachineManagement />} />
                <Route path="/manage-anomalies" element={<ManageAnomalies />} />
                <Route path="/cutting-history" element={<CuttingHistory />} />
                <Route path="/resource-allocation" element={<ResourceAllocation />} />
                <Route path="/cutting-dashboard" element={<CuttingDashboardPage />} />
                <Route path="/assembly-tasks" element={<AssemblyTasksList />} />
                <Route path="/create-assembly-task" element={<CreateAssemblyTask />} />
                <Route path="/assembly-task/:id" element={<AssemblyTaskDetailsPage />} />
                <Route path="/ingredient-usage" element={<IngredientUsage />} />
                <Route path="/resource-allocation" element={<ResourceAllocation />} />
                <Route path="/assembly-history" element={<AssemblyHistory />} />
                <Route path="/manage-assembly-anomalies" element={<ManageAssemblyAnomalies />} />
                <Route path="/assembly-dashboard" element={<AssemblyDashboardPage />} />
                <Route path="/production-tasks" element={<ProductionTaskList />} />
                <Route path="/task-assignment" element={<TaskAssignment />} />
                <Route path="/real-time-production" element={<RealTimeProduction />} />
                <Route path="/production-history" element={<ProductionHistory />} />
                <Route path="/production-dashboard" element={<ProductionDashboardPage />} />
                <Route path="/production-step/:id" element={<ProductionStepDetailsPage />} />
                <Route path="/manage-production-anomalies" element={<ManageProductionAnomalies />} />
                <Route path="/machine-tracking" element={<MachineTracking />} />
                <Route path="/operator-tracking" element={<OperatorTracking />} />
                <Route path="/internal-validations" element={<InternalValidationList />} />
                <Route path="/validation-details/:id" element={<ValidationDetails />} />
                <Route path="/report-defects/:id" element={<ReportDefects />} />
                <Route path="/batch-validation" element={<BatchValidation />} />
                <Route path="/validation-dashboard" element={<ValidationDashboard />} />
                <Route path="/validation-history" element={<ValidationHistory />} />
                <Route path="/rejected-pieces" element={<RejectedPieces />} />
                <Route path="/defects-report" element={<DefectsReport />} />
                <Route path="/quality-inspections" element={<QualityInspectionList />} />
                <Route path="/quality-inspection-details/:id" element={<QualityInspectionDetails />} />
                <Route path="/report-quality-defects/:id" element={<ReportQualityDefects />} />
                <Route path="/non-conforming-pieces" element={<NonConformingPieces />} />
                <Route path="/validation-corrections" element={<ValidationCorrections />} />
                <Route path="/quality-control-dashboard" element={<QualityControlDashboard />} />
                <Route path="/quality-control-history" element={<QualityControlHistory />} />
                <Route path="/quality-defects-report" element={<QualityDefectsReport />} />
                <Route path="/rejected-pieces" element={<RejectedPiecesList />} />
                <Route path="/report-defects-for-correction" element={<ReportDefectsForCorrection />} />
                <Route path="/plan-corrections" element={<PlanCorrections />} />
                <Route path="/corrections-tracking" element={<CorrectionsTracking />} />
                <Route path="/replacements-management" element={<ReplacementsManagement />} />
                <Route path="/corrections-validation" element={<CorrectionsValidation />} />
                <Route path="/corrections-history" element={<CorrectionsHistory />} />
                <Route path="/corrections-dashboard" element={<CorrectionsDashboard />} />
                <Route path="/packaging-list" element={<PackagingList />} />
                <Route path="/package-box" element={<PackageBox />} />
                <Route path="/packaged-boxes-tracking" element={<PackagedBoxesTracking />} />
                <Route path="/packaged-box/:id" element={<PackagedBoxDetails />} />
                <Route path="/packaging-resources-tracking" element={<PackagingResourcesTracking />} />
                <Route path="/packaging-anomalies" element={<PackagingAnomalies />} />
                <Route path="/packaging-history" element={<PackagingHistory />} />
                <Route path="/packaging-dashboard" element={<PackagingDashboard />} />
                <Route path="/label-document-generation" element={<LabelAndDocumentGeneration />} />
                <Route path="/shipping-list" element={<ShippingList />} />
                <Route path="/prepare-shipment" element={<PrepareShipment />} />
                <Route path="/shipping-tracking" element={<ShippingTracking />} />
                <Route path="/shipment-details/:id" element={<ShipmentDetails />} />
                <Route path="/shipping-anomalies" element={<ShippingAnomalies />} />
                <Route path="/shipping-history" element={<ShippingHistory />} />
                <Route path="/shipping-dashboard" element={<ShippingDashboard />} />
                <Route path="/generate-shipping-documents" element={<GenerateShippingDocuments />} />
                <Route path="/equipment-list" element={<EquipmentList />} />
                <Route path="/add-equipment" element={<AddOrEditEquipment />} />
                <Route path="/edit-equipment/:id" element={<AddOrEditEquipment />} />
                <Route path="/real-time-equipment" element={<RealTimeEquipment />} />
                <Route path="/maintenance-planning" element={<MaintenancePlanning />} />
                <Route path="/maintenance-history" element={<MaintenanceHistory />} />
                <Route path="/equipment-breakdowns" element={<EquipmentBreakdowns />} />
                <Route path="/equipment-dashboard" element={<EquipmentDashboard />} />
                <Route path="/equipment-lifecycle" element={<EquipmentLifecycle />} />
                <Route path="/spare-parts-management" element={<SparePartsManagement />} />

            </Routes>
        </AppLayout>
    </Router>
);

export default App;
