import React, { useState } from "react";
import { shippingData } from "../services/shippingData";

const PrepareShipment = () => {
    const [selectedBoxes, setSelectedBoxes] = useState([]);
    const [shipmentDetails, setShipmentDetails] = useState({
        carrier: "",
        destination: "",
    });

    const handleBoxSelection = (boxId) => {
        setSelectedBoxes((prevSelected) =>
            prevSelected.includes(boxId)
                ? prevSelected.filter((id) => id !== boxId)
                : [...prevSelected, boxId]
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!shipmentDetails.carrier || !shipmentDetails.destination || selectedBoxes.length === 0) {
            alert("Veuillez remplir tous les champs et sélectionner au moins une boîte.");
            return;
        }

        alert(`Expédition préparée avec succès :
      Transporteur : ${shipmentDetails.carrier}
      Destination : ${shipmentDetails.destination}
      Boîtes : ${selectedBoxes.join(", ")}`);
        // Ajouter la logique pour sauvegarder l'expédition ou envoyer les données à une API

        setShipmentDetails({ carrier: "", destination: "" });
        setSelectedBoxes([]);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Préparation d’une expédition</h1>

            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-4">
                <div className="mb-4">
                    <label className="block font-semibold mb-2">Transporteur :</label>
                    <input
                        type="text"
                        name="carrier"
                        value={shipmentDetails.carrier}
                        onChange={handleInputChange}
                        placeholder="Nom du transporteur"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-2">Destination :</label>
                    <input
                        type="text"
                        name="destination"
                        value={shipmentDetails.destination}
                        onChange={handleInputChange}
                        placeholder="Adresse ou ville de destination"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-2">Sélectionnez les boîtes :</label>
                    {shippingData.filter((box) => box.status === "Prête pour expédition").length > 0 ? (
                        <table className="w-full border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="border border-gray-300 p-2">Sélectionner</th>
                                    <th className="border border-gray-300 p-2">Nom</th>
                                    <th className="border border-gray-300 p-2">Destination</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shippingData
                                    .filter((box) => box.status === "Prête pour expédition")
                                    .map((box) => (
                                        <tr key={box.id}>
                                            <td className="border border-gray-300 p-2 text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedBoxes.includes(box.id)}
                                                    onChange={() => handleBoxSelection(box.id)}
                                                />
                                            </td>
                                            <td className="border border-gray-300 p-2">{box.name}</td>
                                            <td className="border border-gray-300 p-2">{box.destination}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-center text-gray-500">Aucune boîte prête pour expédition.</p>
                    )}
                </div>

                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Préparer l’expédition
                </button>
            </form>
        </div>
    );
};

export default PrepareShipment;
