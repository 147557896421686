export const productionSteps = [
    {
        id: 1,
        name: "Découpe tissu A",
        status: "En cours",
        timeElapsed: "1h 30m",
        operatorId: 1,
        machineId: 1,
    },
    {
        id: 2,
        name: "Assemblage poches",
        status: "Terminée",
        timeElapsed: "2h 00m",
        operatorId: 2,
        machineId: 2,
    },
    {
        id: 3,
        name: "Couture manches",
        status: "Planifiée",
        timeElapsed: "0h 00m",
        operatorId: null,
        machineId: null,
    },
];
