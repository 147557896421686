import React, { useState } from "react";
import { packagedBoxesData } from "../services/packagingData";

const LabelAndDocumentGeneration = () => {
    const [selectedBoxes, setSelectedBoxes] = useState([]);

    const handleBoxSelection = (boxId) => {
        setSelectedBoxes((prevSelected) =>
            prevSelected.includes(boxId)
                ? prevSelected.filter((id) => id !== boxId)
                : [...prevSelected, boxId]
        );
    };

    const handleGenerateDocuments = () => {
        if (selectedBoxes.length === 0) {
            alert("Veuillez sélectionner au moins une boîte pour générer les documents.");
            return;
        }

        alert(`Documents générés pour les boîtes : ${selectedBoxes.join(", ")}`);
        // Logique pour la génération réelle des documents ou l'appel à une API
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Génération des étiquettes et documents d’expédition</h1>

            {/* Tableau des boîtes prêtes */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Sélectionnez les boîtes prêtes à expédier :</h2>
                {packagedBoxesData.filter((box) => box.status === "Prête à expédier").length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Sélectionner</th>
                                <th className="border border-gray-300 p-2">Nom de la boîte</th>
                                <th className="border border-gray-300 p-2">Contenu</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packagedBoxesData
                                .filter((box) => box.status === "Prête à expédier")
                                .map((box) => (
                                    <tr key={box.id}>
                                        <td className="border border-gray-300 p-2 text-center">
                                            <input
                                                type="checkbox"
                                                checked={selectedBoxes.includes(box.id)}
                                                onChange={() => handleBoxSelection(box.id)}
                                            />
                                        </td>
                                        <td className="border border-gray-300 p-2">{box.name}</td>
                                        <td className="border border-gray-300 p-2">{box.contents.join(", ")}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune boîte prête à expédier.</p>
                )}
            </div>

            {/* Bouton de génération */}
            <div className="mt-4">
                <button
                    onClick={handleGenerateDocuments}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Générer les documents
                </button>
            </div>
        </div>
    );
};

export default LabelAndDocumentGeneration;
