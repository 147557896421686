import React, { useState } from "react";
import { machines } from "../services/machineData";

const MachineTracking = () => {
    const [filter, setFilter] = useState("");
    const [machineList, setMachineList] = useState(machines);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleUpdateStatus = (id, newStatus) => {
        const updatedMachines = machineList.map((machine) =>
            machine.id === id ? { ...machine, status: newStatus } : machine
        );
        setMachineList(updatedMachines);
    };

    const filteredMachines = machineList.filter(
        (machine) =>
            machine.name.toLowerCase().includes(filter.toLowerCase()) ||
            machine.status.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des machines de production</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des machines */}
            {filteredMachines.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Tâches associées</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMachines.map((machine) => (
                            <tr key={machine.id}>
                                <td className="border border-gray-300 p-2">{machine.name}</td>
                                <td className="border border-gray-300 p-2">{machine.status}</td>
                                <td className="border border-gray-300 p-2">{machine.tasks || "Aucune"}</td>
                                <td className="border border-gray-300 p-2">
                                    {machine.status === "En service" && (
                                        <button
                                            onClick={() => handleUpdateStatus(machine.id, "En maintenance")}
                                            className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700 mr-2"
                                        >
                                            Mettre en maintenance
                                        </button>
                                    )}
                                    {machine.status === "En maintenance" && (
                                        <button
                                            onClick={() => handleUpdateStatus(machine.id, "En service")}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                        >
                                            Remettre en service
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune machine trouvée.</p>
            )}
        </div>
    );
};

export default MachineTracking;
