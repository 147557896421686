import React, { useState } from "react";
import { packagingHistoryData } from "../services/packagingData";

const PackagingHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
        setCurrentPage(1); // Réinitialiser à la première page après un filtrage
    };

    // Filtrage des données
    const filteredData = packagingHistoryData.filter(
        (item) =>
            item.boxName.toLowerCase().includes(filter) ||
            item.date.toLowerCase().includes(filter) ||
            item.status.toLowerCase().includes(filter)
    );

    // Pagination
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des emballages</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom de boîte, date ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau de l'historique */}
            {paginatedData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la boîte</th>
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Contenu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.boxName}</td>
                                <td className="border border-gray-300 p-2">{item.date}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${item.status === "Expédiée"
                                            ? "text-green-500"
                                            : "text-orange-500"
                                        }`}
                                >
                                    {item.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {item.contents.join(", ")}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun historique trouvé.</p>
            )}

            {/* Pagination */}
            {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-3 py-1 border ${currentPage === index + 1
                                    ? "bg-blue-500 text-white"
                                    : "bg-white text-blue-500"
                                } hover:bg-blue-700 hover:text-white mx-1`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PackagingHistory;
