import React, { useState } from "react";
import { defectReportsData } from "../services/defectReportsData";

const ReportDefectsForCorrection = () => {
    const [defects, setDefects] = useState(defectReportsData);
    const [form, setForm] = useState({
        pieceName: "",
        anomalyType: "",
        description: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!form.pieceName || !form.anomalyType || !form.description) {
            alert("Veuillez remplir tous les champs.");
            return;
        }

        const newDefect = {
            id: defects.length + 1,
            pieceName: form.pieceName,
            anomalyType: form.anomalyType,
            description: form.description,
            status: "En attente",
            date: new Date().toISOString().split("T")[0], // Date actuelle
        };

        setDefects([...defects, newDefect]);
        setForm({
            pieceName: "",
            anomalyType: "",
            description: "",
        });
        alert("Défaut signalé avec succès !");
    };

    const handleMarkAsCorrected = (id) => {
        alert(`Le défaut avec ID ${id} a été marqué comme corrigé.`);
        setDefects(
            defects.map((defect) =>
                defect.id === id ? { ...defect, status: "Corrigé" } : defect
            )
        );
    };

    const handleEscalateForReplacement = (id) => {
        alert(`Le défaut avec ID ${id} a été escaladé pour un remplacement.`);
        setDefects(
            defects.map((defect) =>
                defect.id === id ? { ...defect, status: "Remplacement demandé" } : defect
            )
        );
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Signalement des anomalies</h1>

            {/* Formulaire de signalement */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Signaler une anomalie</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Nom de la pièce :</label>
                        <input
                            type="text"
                            name="pieceName"
                            value={form.pieceName}
                            onChange={handleInputChange}
                            placeholder="Nom de la pièce"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Type d’anomalie :</label>
                        <input
                            type="text"
                            name="anomalyType"
                            value={form.anomalyType}
                            onChange={handleInputChange}
                            placeholder="Type d’anomalie"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-semibold mb-2">Description :</label>
                        <textarea
                            name="description"
                            value={form.description}
                            onChange={handleInputChange}
                            placeholder="Description de l’anomalie"
                            className="border border-gray-300 rounded p-2 w-full"
                            rows="4"
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    >
                        Signaler
                    </button>
                </form>
            </div>

            {/* Tableau de suivi des anomalies */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Suivi des anomalies signalées</h2>
                {defects.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Date</th>
                                <th className="border border-gray-300 p-2">Nom de la pièce</th>
                                <th className="border border-gray-300 p-2">Type d’anomalie</th>
                                <th className="border border-gray-300 p-2">Statut</th>
                                <th className="border border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {defects.map((defect) => (
                                <tr key={defect.id}>
                                    <td className="border border-gray-300 p-2">{defect.date}</td>
                                    <td className="border border-gray-300 p-2">{defect.pieceName}</td>
                                    <td className="border border-gray-300 p-2">{defect.anomalyType}</td>
                                    <td
                                        className={`border border-gray-300 p-2 ${defect.status === "Corrigé"
                                                ? "text-green-500"
                                                : defect.status === "Remplacement demandé"
                                                    ? "text-yellow-500"
                                                    : "text-orange-500"
                                            }`}
                                    >
                                        {defect.status}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {defect.status === "En attente" && (
                                            <>
                                                <button
                                                    onClick={() => handleMarkAsCorrected(defect.id)}
                                                    className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                                >
                                                    Marquer comme corrigé
                                                </button>
                                                <button
                                                    onClick={() => handleEscalateForReplacement(defect.id)}
                                                    className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700"
                                                >
                                                    Escalader pour remplacement
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucune anomalie signalée.</p>
                )}
            </div>
        </div>
    );
};

export default ReportDefectsForCorrection;
