import React from "react";
import styles from "./CorrectionTable.module.css";

const CorrectionTable = ({ corrections }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Pièce</th>
                <th>Statut</th>
                <th>Raison</th>
                <th>Assigné à</th>
            </tr>
        </thead>
        <tbody>
            {corrections.map((correction) => (
                <tr key={correction.id}>
                    <td>{correction.id}</td>
                    <td>{correction.piece}</td>
                    <td>
                        <span
                            className={
                                correction.status === "Terminée"
                                    ? styles.completed
                                    : correction.status === "En cours"
                                        ? styles.inProgress
                                        : styles.pending
                            }
                        >
                            {correction.status}
                        </span>
                    </td>
                    <td>{correction.reason}</td>
                    <td>{correction.assignedTo}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default CorrectionTable;
