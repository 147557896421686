export const breakdownData = [
    {
        id: 1,
        equipment: "Machine A",
        description: "Problème de moteur.",
        status: "En attente",
        dateReported: "2024-11-20",
    },
    {
        id: 2,
        equipment: "Outil B",
        description: "Fuite d'huile.",
        status: "Résolue",
        dateReported: "2024-11-18",
    },
];
