import React, { useState } from "react";
import { correctionsValidationData } from "../services/correctionsValidationData";

const CorrectionsValidation = () => {
    const [filter, setFilter] = useState("");
    const [validationData, setValidationData] = useState(correctionsValidationData);

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredData = validationData.filter(
        (item) =>
            item.pieceName.toLowerCase().includes(filter) ||
            item.status.toLowerCase().includes(filter) ||
            item.operator.toLowerCase().includes(filter)
    );

    const handleValidate = (id) => {
        alert(`La pièce avec ID ${id} a été validée.`);
        setValidationData(
            validationData.map((item) =>
                item.id === id ? { ...item, status: "Validée" } : item
            )
        );
    };

    const handleReject = (id) => {
        alert(`La pièce avec ID ${id} a été rejetée.`);
        setValidationData(
            validationData.map((item) =>
                item.id === id ? { ...item, status: "Rejetée à nouveau" } : item
            )
        );
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Validation des corrections et remplacements</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, opérateur ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des pièces prêtes pour validation */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.pieceName}</td>
                                <td className="border border-gray-300 p-2">{item.operator}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${item.status === "Prête pour validation"
                                            ? "text-blue-500"
                                            : item.status === "Validée"
                                                ? "text-green-500"
                                                : "text-red-500"
                                        }`}
                                >
                                    {item.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {item.status === "Prête pour validation" && (
                                        <>
                                            <button
                                                onClick={() => handleValidate(item.id)}
                                                className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                            >
                                                Valider
                                            </button>
                                            <button
                                                onClick={() => handleReject(item.id)}
                                                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                            >
                                                Rejeter
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce prête pour validation.</p>
            )}
        </div>
    );
};

export default CorrectionsValidation;
