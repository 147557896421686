import React from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend,
    PointElement,
} from "chart.js";
import { productionTasks } from "../services/productionTasksData";
import { productionHistory } from "../services/productionHistoryData";
import { productionAnomalies } from "../services/productionAnomaliesData";

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement,ArcElement, Tooltip, Legend);

const ProductionDashboardPage = () => {
    // KPI Calculations
    const totalTasks = productionTasks.length;
    const completedTasks = productionTasks.filter((task) => task.status === "Terminée").length;
    const inProgressTasks = productionTasks.filter((task) => task.status === "En cours").length;
    const anomalies = productionAnomalies.length;

    // Bar Chart Data (Progression des tâches)
    const barData = {
        labels: productionTasks.map((task) => task.name),
        datasets: [
            {
                label: "Progression des tâches (%)",
                data: productionTasks.map((task) => (task.status === "Terminée" ? 100 : 50)),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    // Pie Chart Data (Taux de défauts)
    const pieData = {
        labels: ["Tâches terminées", "Tâches en cours"],
        datasets: [
            {
                data: [completedTasks, inProgressTasks],
                backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    // Line Chart Data (Temps moyen par étape)
    const lineData = {
        labels: productionHistory.map((entry) => entry.taskName),
        datasets: [
            {
                label: "Temps moyen (minutes)",
                data: productionHistory.map((entry) => parseInt(entry.duration)),
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
            },
        ],
    };

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord de la production</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Tâches totales</h2>
                    <p className="text-3xl font-bold">{totalTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Tâches terminées</h2>
                    <p className="text-3xl font-bold">{completedTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Tâches en cours</h2>
                    <p className="text-3xl font-bold">{inProgressTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Anomalies détectées</h2>
                    <p className="text-3xl font-bold text-red-500">{anomalies}</p>
                </div>
            </div>

            {/* Charts Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Progression des tâches</h2>
                    <Bar data={barData} options={barOptions} />
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des tâches</h2>
                    <Pie data={pieData} options={pieOptions} />
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Temps moyen par étape</h2>
                    <Line data={lineData} options={lineOptions} />
                </div>
            </div>
        </div>
    );
};

export default ProductionDashboardPage;
