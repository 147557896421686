import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { defectData } from "../services/defectsData";

ChartJS.register(...registerables);

const DefectsReport = () => {
    // Données pour le tableau et les graphiques
    const defectTypes = Array.from(
        defectData.reduce((acc, defect) => {
            acc.set(defect.type, (acc.get(defect.type) || 0) + 1);
            return acc;
        }, new Map())
    );

    const defectByOperator = Array.from(
        defectData.reduce((acc, defect) => {
            acc.set(defect.operator, (acc.get(defect.operator) || 0) + 1);
            return acc;
        }, new Map())
    );

    const pieData = {
        labels: defectTypes.map(([type]) => type),
        datasets: [
            {
                data: defectTypes.map(([, count]) => count),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
            },
        ],
    };

    const barData = {
        labels: defectByOperator.map(([operator]) => operator),
        datasets: [
            {
                label: "Nombre de défauts",
                data: defectByOperator.map(([, count]) => count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Rapport des défauts détectés</h1>

            {/* Tableau des défauts */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">Tableau des défauts</h2>
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Type de défaut</th>
                            <th className="border border-gray-300 p-2">Pièce</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defectData.map((defect) => (
                            <tr key={defect.id}>
                                <td className="border border-gray-300 p-2">{defect.date}</td>
                                <td className="border border-gray-300 p-2">{defect.type}</td>
                                <td className="border border-gray-300 p-2">{defect.piece}</td>
                                <td className="border border-gray-300 p-2">{defect.operator}</td>
                                <td className="border border-gray-300 p-2">{defect.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des types de défauts</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Défauts par opérateur</h2>
                    <Bar data={barData} />
                </div>
            </div>
        </div>
    );
};

export default DefectsReport;
