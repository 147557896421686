import React, { useState } from "react";
import { replacementsData } from "../services/replacementsData";

const ReplacementsManagement = () => {
    const [filter, setFilter] = useState("");
    const [replacements, setReplacements] = useState(replacementsData);

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredReplacements = replacements.filter(
        (replacement) =>
            replacement.pieceName.toLowerCase().includes(filter) ||
            replacement.reason.toLowerCase().includes(filter) ||
            replacement.status.toLowerCase().includes(filter)
    );

    const handleCreateCuttingTask = (id) => {
        alert(`Nouvelle tâche de découpe créée pour la pièce avec ID ${id}.`);
        setReplacements(
            replacements.map((replacement) =>
                replacement.id === id ? { ...replacement, status: "Remplacement en cours" } : replacement
            )
        );
    };

    const handleCreateAssemblyTask = (id) => {
        alert(`Nouvelle tâche d'assemblage créée pour la pièce avec ID ${id}.`);
        setReplacements(
            replacements.map((replacement) =>
                replacement.id === id ? { ...replacement, status: "Remplacement en cours" } : replacement
            )
        );
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des remplacements</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, raison ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des remplacements */}
            {filteredReplacements.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Raison</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReplacements.map((replacement) => (
                            <tr key={replacement.id}>
                                <td className="border border-gray-300 p-2">{replacement.pieceName}</td>
                                <td className="border border-gray-300 p-2">{replacement.reason}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${replacement.status === "Remplacement en cours"
                                            ? "text-blue-500"
                                            : "text-red-500"
                                        }`}
                                >
                                    {replacement.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleCreateCuttingTask(replacement.id)}
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700 mr-2"
                                    >
                                        Créer tâche de découpe
                                    </button>
                                    <button
                                        onClick={() => handleCreateAssemblyTask(replacement.id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                    >
                                        Créer tâche d'assemblage
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce nécessitant un remplacement.</p>
            )}
        </div>
    );
};

export default ReplacementsManagement;
