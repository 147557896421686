export const cuttingHistory = [
    {
        id: 1,
        date: "2024-11-20",
        taskName: "Découpe de tissu A",
        operator: "Jean Dupont",
        quantity: 50,
        machine: "Machine A",
    },
    {
        id: 2,
        date: "2024-11-18",
        taskName: "Découpe de tissu B",
        operator: "Marie Curie",
        quantity: 30,
        machine: "Machine B",
    },
    {
        id: 3,
        date: "2024-11-19",
        taskName: "Découpe de tissu C",
        operator: "Paul Martin",
        quantity: 40,
        machine: "Machine C",
    },
    {
        id: 4,
        date: "2024-11-21",
        taskName: "Découpe de tissu D",
        operator: "Alice Doe",
        quantity: 25,
        machine: "Machine D",
    },
];
