export const assemblyAnomalies = [
    {
        id: 1,
        description: "Couture incorrecte des poches",
        taskId: "101",
        operator: "Jean Dupont",
        status: "Signalée",
        date: "2024-11-22",
    },
    {
        id: 2,
        description: "Mauvais assemblage des manches",
        taskId: "102",
        operator: "Marie Curie",
        status: "Résolue",
        date: "2024-11-21",
    },
];
