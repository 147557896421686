import React, { useState } from "react";
import { ingredients } from "../services/ingredientsData";

const IngredientUsage = () => {
    const CRITICAL_THRESHOLD = 100; // Seuil critique pour les notifications
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredIngredients = ingredients.filter((ingredient) =>
        ingredient.name.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des ingrédients utilisés</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher un ingrédient..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des ingrédients */}
            {filteredIngredients.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Quantité disponible</th>
                            <th className="border border-gray-300 p-2">Unité</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredIngredients.map((ingredient) => (
                            <tr key={ingredient.id}>
                                <td className="border border-gray-300 p-2">{ingredient.name}</td>
                                <td className="border border-gray-300 p-2">{ingredient.quantity}</td>
                                <td className="border border-gray-300 p-2">{ingredient.unit}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${ingredient.quantity < CRITICAL_THRESHOLD
                                            ? "text-red-500 font-bold"
                                            : "text-green-500"
                                        }`}
                                >
                                    {ingredient.quantity < CRITICAL_THRESHOLD
                                        ? "Critique"
                                        : "Suffisant"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun ingrédient trouvé.</p>
            )}
        </div>
    );
};

export default IngredientUsage;
