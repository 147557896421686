import React, { useState } from "react";
import { cuttingHistory } from "../services/cuttingHistoryData";

const CuttingHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Filtrage des données en fonction de la recherche
    const filteredHistory = cuttingHistory.filter(
        (history) =>
            history.taskName.toLowerCase().includes(filter.toLowerCase()) ||
            history.operator.toLowerCase().includes(filter.toLowerCase()) ||
            history.date.includes(filter)
    );

    // Pagination
    const totalPages = Math.ceil(filteredHistory.length / itemsPerPage);
    const paginatedHistory = filteredHistory.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Réinitialise la pagination lors du changement de filtre
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === "next" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des découpes</h1>

            {/* Champ de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par tâche, opérateur ou date..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des découpes */}
            {paginatedHistory.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Nom de la tâche</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Quantité</th>
                            <th className="border border-gray-300 p-2">Machine</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedHistory.map((history) => (
                            <tr key={history.id}>
                                <td className="border border-gray-300 p-2">{history.date}</td>
                                <td className="border border-gray-300 p-2">{history.taskName}</td>
                                <td className="border border-gray-300 p-2">{history.operator}</td>
                                <td className="border border-gray-300 p-2">{history.quantity}</td>
                                <td className="border border-gray-300 p-2">{history.machine}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune découpe trouvée.</p>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-4">
                <button
                    onClick={() => handlePageChange("prev")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === 1}
                >
                    Précédent
                </button>
                <span>
                    Page {currentPage} sur {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange("next")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === totalPages}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default CuttingHistory;
