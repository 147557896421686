export const sparePartsData = [
    {
        id: 1,
        name: "Roulement à billes",
        stock: 15,
        criticalThreshold: 10,
    },
    {
        id: 2,
        name: "Courroie de transmission",
        stock: 5,
        criticalThreshold: 8,
    },
    {
        id: 3,
        name: "Joint d'étanchéité",
        stock: 20,
        criticalThreshold: 5,
    },
    {
        id: 4,
        name: "Filtre à huile",
        stock: 2,
        criticalThreshold: 5,
    },
];
