import React, { useState } from "react";
import { suppliers } from "../services/suppliersData";

const Suppliers = () => {
    const [supplierList, setSupplierList] = useState(suppliers);
    const [form, setForm] = useState({ id: null, name: "", contact: "", editing: false });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleAddSupplier = (e) => {
        e.preventDefault();
        if (form.name && form.contact) {
            const newSupplier = {
                id: supplierList.length + 1,
                name: form.name,
                contact: form.contact,
            };
            setSupplierList([...supplierList, newSupplier]);
            setForm({ id: null, name: "", contact: "", editing: false });
        } else {
            alert("Veuillez remplir tous les champs.");
        }
    };

    const handleEditSupplier = (supplier) => {
        setForm({ id: supplier.id, name: supplier.name, contact: supplier.contact, editing: true });
    };

    const handleUpdateSupplier = (e) => {
        e.preventDefault();
        if (form.name && form.contact) {
            setSupplierList(
                supplierList.map((supplier) =>
                    supplier.id === form.id ? { ...supplier, name: form.name, contact: form.contact } : supplier
                )
            );
            setForm({ id: null, name: "", contact: "", editing: false });
        } else {
            alert("Veuillez remplir tous les champs.");
        }
    };

    const handleDeleteSupplier = (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce fournisseur ?")) {
            setSupplierList(supplierList.filter((supplier) => supplier.id !== id));
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Fournisseurs</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-4">
                    {form.editing ? "Modifier un fournisseur" : "Ajouter un fournisseur"}
                </h2>
                <form onSubmit={form.editing ? handleUpdateSupplier : handleAddSupplier} className="space-y-4">
                    <div>
                        <label className="block mb-2 font-semibold">Nom :</label>
                        <input
                            type="text"
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                            placeholder="Nom du fournisseur"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-semibold">Contact :</label>
                        <input
                            type="text"
                            name="contact"
                            value={form.contact}
                            onChange={handleInputChange}
                            placeholder="Email ou téléphone"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`bg-${form.editing ? "yellow" : "green"}-500 text-white px-4 py-2 rounded hover:bg-${form.editing ? "yellow" : "green"
                            }-700`}
                    >
                        {form.editing ? "Mettre à jour" : "Ajouter"}
                    </button>
                    {form.editing && (
                        <button
                            type="button"
                            onClick={() => setForm({ id: null, name: "", contact: "", editing: false })}
                            className="ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                        >
                            Annuler
                        </button>
                    )}
                </form>
            </div>

            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Liste des Fournisseurs</h2>
                {supplierList.length > 0 ? (
                    <table className="w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 p-2">Nom</th>
                                <th className="border border-gray-300 p-2">Contact</th>
                                <th className="border border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierList.map((supplier) => (
                                <tr key={supplier.id}>
                                    <td className="border border-gray-300 p-2">{supplier.name}</td>
                                    <td className="border border-gray-300 p-2">{supplier.contact}</td>
                                    <td className="border border-gray-300 p-2">
                                        <button
                                            onClick={() => handleEditSupplier(supplier)}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mr-2"
                                        >
                                            Modifier
                                        </button>
                                        <button
                                            onClick={() => handleDeleteSupplier(supplier.id)}
                                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                                        >
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-500">Aucun fournisseur disponible.</p>
                )}
            </div>
        </div>
    );
};

export default Suppliers;
