import React from "react";
import styles from "./StepTable.module.css";

const StepTable = ({ steps }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Étape</th>
                <th>Statut</th>
                <th>Opérateur</th>
                <th>Machine</th>
                <th>Pièces traitées</th>
            </tr>
        </thead>
        <tbody>
            {steps.map((step) => (
                <tr key={step.id}>
                    <td>{step.id}</td>
                    <td>{step.stepName}</td>
                    <td>
                        <span
                            className={
                                step.status === "Terminée"
                                    ? styles.completed
                                    : step.status === "En cours"
                                        ? styles.inProgress
                                        : styles.pending
                            }
                        >
                            {step.status}
                        </span>
                    </td>
                    <td>{step.operator}</td>
                    <td>{step.machine}</td>
                    <td>{step.piecesProcessed}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default StepTable;
