import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const EquipmentDashboard = () => {
    // Données des KPI
    const stats = {
        meanTimeBetweenFailures: 45, // en jours
        maintenanceCosts: 1200, // en euros
        availabilityRate: 85, // en pourcentage
        equipmentStatus: {
            operational: 70,
            maintenance: 20,
            outOfService: 10,
        },
        breakdownCauses: [
            { cause: "Usure", count: 25 },
            { cause: "Mauvais usage", count: 15 },
            { cause: "Défaut matériel", count: 10 },
        ],
    };

    // Données pour les graphiques
    const pieData = {
        labels: ["Opérationnels", "En maintenance", "Hors service"],
        datasets: [
            {
                data: [
                    stats.equipmentStatus.operational,
                    stats.equipmentStatus.maintenance,
                    stats.equipmentStatus.outOfService,
                ],
                backgroundColor: ["#4caf50", "#ff9800", "#f44336"],
            },
        ],
    };

    const barData = {
        labels: stats.breakdownCauses.map((item) => item.cause),
        datasets: [
            {
                label: "Causes des pannes",
                data: stats.breakdownCauses.map((item) => item.count),
                backgroundColor: "rgba(54, 162, 235, 0.6)",
            },
        ],
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord des matériels</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Temps moyen entre pannes</h2>
                    <p className="text-3xl font-bold">{stats.meanTimeBetweenFailures} jours</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Coûts d’entretien</h2>
                    <p className="text-3xl font-bold text-blue-500">{stats.maintenanceCosts} €</p>
                </div>
                <div className="bg-white shadow-md rounded p-4 text-center">
                    <h2 className="text-lg font-bold">Taux de disponibilité</h2>
                    <p className="text-3xl font-bold text-green-500">{stats.availabilityRate}%</p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-2 gap-6">
                {/* Graphique en camembert */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Statut des matériels</h2>
                    <Pie data={pieData} />
                </div>

                {/* Graphique à barres */}
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Causes des pannes</h2>
                    <Bar data={barData} />
                </div>
            </div>
        </div>
    );
};

export default EquipmentDashboard;
