import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { materials } from "../services/materialsData";

const UpdateStock = () => {
    const { id } = useParams(); // Récupère l'ID de la matière depuis l'URL
    const navigate = useNavigate();

    // Trouve la matière correspondante
    const material = materials.find((mat) => mat.id === parseInt(id));

    const [form, setForm] = useState({
        adjustment: "",
        reason: "",
    });

    if (!material) {
        return <p className="text-center text-red-500">Matière non trouvée.</p>;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!form.adjustment || !form.reason) {
            alert("Veuillez remplir tous les champs.");
            return;
        }

        // Simuler la mise à jour du stock
        const updatedQuantity =
            parseInt(material.quantity) + parseInt(form.adjustment);
        console.log(`Mise à jour du stock pour ${material.name}: ${updatedQuantity}`);

        // Redirige vers la liste des matières après la mise à jour
        navigate("/");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Mise à jour du stock</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Matière : {material.name}</h2>
                <p>
                    <strong>Quantité actuelle :</strong> {material.quantity} {material.unit}
                </p>
                <p>
                    <strong>Fournisseur :</strong> {material.supplier}
                </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4 bg-white shadow-md rounded p-4">
                <div>
                    <label className="block mb-2 font-semibold">Ajustement de stock :</label>
                    <input
                        type="number"
                        name="adjustment"
                        value={form.adjustment}
                        onChange={handleChange}
                        placeholder="Ex: 50 ou -20"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                    <small className="text-gray-500">
                        Entrez une valeur positive pour une augmentation ou négative pour une diminution.
                    </small>
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Raison :</label>
                    <textarea
                        name="reason"
                        value={form.reason}
                        onChange={handleChange}
                        placeholder="Ex: Réception de stock supplémentaire ou utilisation en production"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Mettre à jour
                </button>
            </form>
        </div>
    );
};

export default UpdateStock;
