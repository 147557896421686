import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { productionTasks } from "../services/productionTasksData";
import { machines } from "../services/machineData";
import { operators } from "../services/operatorData";

const TaskAssignment = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        taskId: "",
        machineId: "",
        operatorId: "",
    });
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        if (!form.taskId || !form.machineId || !form.operatorId) {
            setError("Tous les champs sont obligatoires.");
            setSuccessMessage("");
            return;
        }

        console.log("Tâche attribuée avec succès :", form);

        // Réinitialiser le formulaire
        setForm({
            taskId: "",
            machineId: "",
            operatorId: "",
        });

        setError("");
        setSuccessMessage("Tâche attribuée avec succès !");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Attribution des tâches de production</h1>

            {error && <p className="text-red-500 mb-4">{error}</p>}
            {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

            <div className="bg-white shadow-md rounded p-4">
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Sélection de la tâche */}
                    <div>
                        <label className="block mb-2 font-semibold">Tâche :</label>
                        <select
                            name="taskId"
                            value={form.taskId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez une tâche</option>
                            {productionTasks.map((task) => (
                                <option key={task.id} value={task.id}>
                                    {task.name} ({task.step})
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Sélection de la machine */}
                    <div>
                        <label className="block mb-2 font-semibold">Machine :</label>
                        <select
                            name="machineId"
                            value={form.machineId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez une machine</option>
                            {machines.map((machine) => (
                                <option key={machine.id} value={machine.id}>
                                    {machine.name} ({machine.status})
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Sélection de l'opérateur */}
                    <div>
                        <label className="block mb-2 font-semibold">Opérateur :</label>
                        <select
                            name="operatorId"
                            value={form.operatorId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez un opérateur</option>
                            {operators.map((operator) => (
                                <option key={operator.id} value={operator.id}>
                                    {operator.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex justify-between">
                        <button
                            type="submit"
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                        >
                            Attribuer
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate("/production-tasks")}
                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TaskAssignment;
