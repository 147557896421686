export const rejectedPiecesData = [
    {
        id: 1,
        name: "Pièce A",
        anomalyType: "Couture incorrecte",
        status: "Rejetée",
    },
    {
        id: 2,
        name: "Pièce B",
        anomalyType: "Coupe incorrecte",
        status: "Rejetée",
    },
    {
        id: 3,
        name: "Pièce C",
        anomalyType: "Matière première défectueuse",
        status: "Rejetée",
    },
];
