export const productionTasks = [
    {
        id: 1,
        name: "Découpe tissu A",
        step: "Découpe",
        status: "En cours",
        operatorId: 1,
        machineId: 1,
        progress: 45, // Progression en pourcentage
    },
    {
        id: 2,
        name: "Assemblage poches",
        step: "Assemblage",
        status: "Planifiée",
        operatorId: 2,
        machineId: 2,
        progress: 0,
    },
    {
        id: 3,
        name: "Couture manches",
        step: "Couture",
        status: "Terminée",
        operatorId: 3,
        machineId: 3,
        progress: 100,
    },
];
