import React, { useState } from "react";
import { qualityInspectionHistoryData } from "../services/qualityInspectionHistoryData";

const QualityControlHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Appliquer le filtre
    const filteredData = qualityInspectionHistoryData.filter(
        (item) =>
            item.pieceName.toLowerCase().includes(filter.toLowerCase()) ||
            item.inspector.toLowerCase().includes(filter.toLowerCase()) ||
            item.status.toLowerCase().includes(filter.toLowerCase())
    );

    // Pagination
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Réinitialiser à la première page lors de la recherche
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des contrôles qualité</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom de pièce, inspecteur ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau historique */}
            {paginatedData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Inspecteur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.date}</td>
                                <td className="border border-gray-300 p-2">{item.pieceName}</td>
                                <td className="border border-gray-300 p-2">{item.inspector}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${item.status === "Conforme" ? "text-green-500" : "text-red-500"
                                        }`}
                                >
                                    {item.status}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune inspection trouvée.</p>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-6">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-700"
                        }`}
                >
                    Précédent
                </button>
                <span>
                    Page {currentPage} sur {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-700"
                        }`}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default QualityControlHistory;
