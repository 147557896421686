import React from "react";
import { useNavigate } from "react-router-dom";
import { productionSteps } from "../services/stepData";
import StepTable from "../components/StepTable";

const StepsList = () => {
    const navigate = useNavigate();

    const handleAssignStep = () => {
        navigate("/assign-step-task");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Étapes de production</h1>
            <StepTable steps={productionSteps} />
            <button
                onClick={handleAssignStep}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Assigner une étape
            </button>
        </div>
    );
};

export default StepsList;
