import React, { useState } from "react";
import { shippingTrackingData } from "../services/shippingData";

const ShippingTracking = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredData = shippingTrackingData.filter(
        (shipment) =>
            shipment.id.toString().includes(filter) ||
            shipment.carrier.toLowerCase().includes(filter) ||
            shipment.status.toLowerCase().includes(filter) ||
            shipment.destination.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des expéditions</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par ID, transporteur, statut ou destination..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des expéditions */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">ID</th>
                            <th className="border border-gray-300 p-2">Transporteur</th>
                            <th className="border border-gray-300 p-2">Destination</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Dernière mise à jour</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((shipment) => (
                            <tr key={shipment.id}>
                                <td className="border border-gray-300 p-2">{shipment.id}</td>
                                <td className="border border-gray-300 p-2">{shipment.carrier}</td>
                                <td className="border border-gray-300 p-2">{shipment.destination}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${shipment.status === "Livrée"
                                            ? "text-green-500"
                                            : shipment.status === "Retardée"
                                                ? "text-orange-500"
                                                : "text-red-500"
                                        }`}
                                >
                                    {shipment.status}
                                </td>
                                <td className="border border-gray-300 p-2">{shipment.lastUpdated}</td>
                                <td className="border border-gray-300 p-2">
                                    <a
                                        href={`/shipment-details/${shipment.id}`}
                                        className="text-blue-500 hover:underline"
                                    >
                                        Voir les détails
                                    </a>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune expédition trouvée.</p>
            )}
        </div>
    );
};

export default ShippingTracking;
