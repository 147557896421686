import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { assemblyTasks } from "../services/assemblyTasksData";
import { ingredients } from "../services/ingredientsData";

const CreateAssemblyTask = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: "",
        startDate: "",
        operator: "",
        ingredients: [],
        status: "Planifiée", // Statut par défaut
    });

    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleIngredientChange = (e) => {
        const { value } = e.target;
        const ingredientExists = form.ingredients.includes(value);

        if (ingredientExists) {
            setForm({
                ...form,
                ingredients: form.ingredients.filter((ing) => ing !== value),
            });
        } else {
            setForm({ ...form, ingredients: [...form.ingredients, value] });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des champs
        if (!form.name || !form.startDate || !form.operator || form.ingredients.length === 0) {
            setError("Tous les champs sont obligatoires, y compris les ingrédients.");
            return;
        }

        // Ajout de la nouvelle tâche
        const newTask = {
            id: assemblyTasks.length + 1,
            ...form,
        };
        assemblyTasks.push(newTask);

        console.log("Nouvelle tâche ajoutée :", newTask);

        // Redirection vers la liste des tâches
        navigate("/assembly-tasks");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Créer une nouvelle tâche d'assemblage</h1>

            {error && <p className="text-red-500 mb-4">{error}</p>}

            <form onSubmit={handleSubmit} className="space-y-4 bg-white shadow-md rounded p-4">
                <div>
                    <label className="block mb-2 font-semibold">Nom de la tâche :</label>
                    <input
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                        placeholder="Ex: Assemblage des manches"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Date de début :</label>
                    <input
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Opérateur :</label>
                    <input
                        type="text"
                        name="operator"
                        value={form.operator}
                        onChange={handleChange}
                        placeholder="Nom de l'opérateur"
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Ingrédients nécessaires :</label>
                    <div className="border border-gray-300 rounded p-2">
                        {ingredients.map((ingredient) => (
                            <div key={ingredient.id} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={`ingredient-${ingredient.id}`}
                                    value={ingredient.name}
                                    checked={form.ingredients.includes(ingredient.name)}
                                    onChange={handleIngredientChange}
                                />
                                <label htmlFor={`ingredient-${ingredient.id}`} className="ml-2">
                                    {ingredient.name} ({ingredient.quantity} {ingredient.unit})
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label className="block mb-2 font-semibold">Statut :</label>
                    <select
                        name="status"
                        value={form.status}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    >
                        <option value="Planifiée">Planifiée</option>
                        <option value="En cours">En cours</option>
                        <option value="Terminée">Terminée</option>
                    </select>
                </div>

                <div className="flex justify-between">
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                    >
                        Ajouter la tâche
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate("/assembly-tasks")}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    >
                        Annuler
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateAssemblyTask;
