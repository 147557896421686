import React from "react";
import { useNavigate } from "react-router-dom";
import { materials } from "../services/materialsData";

const CriticalThresholds = () => {
    const navigate = useNavigate();

    // Définir un seuil critique
    const CRITICAL_THRESHOLD = 100;

    // Filtrer les matières sous le seuil critique
    const criticalMaterials = materials.filter(
        (material) => material.quantity < CRITICAL_THRESHOLD
    );

    const handleUpdateStock = (id) => {
        navigate(`/update-stock/${id}`); // Redirige vers la mise à jour de stock
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Matières sous le seuil critique</h1>

            {criticalMaterials.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Quantité</th>
                            <th className="border border-gray-300 p-2">Seuil critique</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {criticalMaterials.map((material) => (
                            <tr key={material.id}>
                                <td className="border border-gray-300 p-2">{material.name}</td>
                                <td className="border border-gray-300 p-2">{material.quantity}</td>
                                <td className="border border-gray-300 p-2">{CRITICAL_THRESHOLD}</td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleUpdateStock(material.id)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                    >
                                        Mettre à jour le stock
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-green-500">
                    Toutes les matières ont des quantités suffisantes.
                </p>
            )}
        </div>
    );
};

export default CriticalThresholds;
