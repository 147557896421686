import React, { useState } from "react";

const ScheduleMaintenance = () => {
    const [form, setForm] = useState({
        equipmentName: "",
        maintenanceDate: "",
        notes: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Entretien planifié :", form);
        setForm({ equipmentName: "", maintenanceDate: "", notes: "" });
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Planifier un entretien</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2 font-semibold">Nom du matériel :</label>
                    <input
                        type="text"
                        name="equipmentName"
                        value={form.equipmentName}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Date de l'entretien :</label>
                    <input
                        type="date"
                        name="maintenanceDate"
                        value={form.maintenanceDate}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 font-semibold">Notes :</label>
                    <textarea
                        name="notes"
                        value={form.notes}
                        onChange={handleChange}
                        className="border border-gray-300 rounded p-2 w-full"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Planifier
                </button>
            </form>
        </div>
    );
};

export default ScheduleMaintenance;
