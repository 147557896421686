export const equipmentData = [
    {
        id: 1,
        name: "Machine A",
        status: "En service",
        lastUsed: "2024-11-20",
        totalLife: 1000, // Durée totale en heures
        usage: 980, // Utilisation actuelle en heures
    },
    {
        id: 2,
        name: "Outil B",
        status: "En maintenance",
        lastUsed: "2024-11-18",
        totalLife: 500,
        usage: 470,
    },
    {
        id: 3,
        name: "Machine C",
        status: "En service",
        lastUsed: "2024-11-21",
        totalLife: 1200,
        usage: 800,
    },
    {
        id: 4,
        name: "Machine D",
        status: "Hors service",
        lastUsed: "2024-11-15",
        totalLife: 600,
        usage: 590,
    },
];
