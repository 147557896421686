import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import { assemblyTasks } from "../services/assemblyTasksData";
import { assemblyHistory } from "../services/assemblyHistoryData";
import { assemblyAnomalies } from "../services/assemblyAnomaliesData";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const AssemblyDashboardPage = () => {
    // KPI Calculations
    const totalTasks = assemblyTasks.length;
    const completedTasks = assemblyTasks.filter((task) => task.status === "Terminée").length;
    const totalPieces = assemblyHistory.reduce((sum, entry) => sum + entry.quantity, 0);
    const unresolvedAnomalies = assemblyAnomalies.filter((anomaly) => anomaly.status === "Signalée").length;

    // Bar Chart Data (Progression des tâches)
    const taskNames = assemblyTasks.map((task) => task.name);
    const taskProgress = assemblyTasks.map((task) =>
        task.status === "Terminée" ? 100 : task.status === "En cours" ? 50 : 0
    );

    const barData = {
        labels: taskNames,
        datasets: [
            {
                label: "Progression des tâches (%)",
                data: taskProgress,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    // Pie Chart Data (Répartition des anomalies)
    const pieData = {
        labels: ["Résolues", "Signalées"],
        datasets: [
            {
                data: [
                    assemblyAnomalies.filter((anomaly) => anomaly.status === "Résolue").length,
                    unresolvedAnomalies,
                ],
                backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Tableau de bord de l'assemblage</h1>

            {/* KPI Section */}
            <div className="grid grid-cols-3 gap-6 mb-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Tâches totales</h2>
                    <p className="text-3xl font-bold">{totalTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Tâches terminées</h2>
                    <p className="text-3xl font-bold">{completedTasks}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Pièces assemblées</h2>
                    <p className="text-3xl font-bold">{totalPieces}</p>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold">Anomalies signalées</h2>
                    <p className="text-3xl font-bold text-red-500">{unresolvedAnomalies}</p>
                </div>
            </div>

            {/* Charts Section */}
            <div className="grid grid-cols-2 gap-6">
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Progression des tâches</h2>
                    <div className="chart-container">
                        <Bar data={barData} options={barOptions} />
                    </div>
                </div>
                <div className="bg-white shadow-md rounded p-4">
                    <h2 className="text-lg font-bold mb-4">Répartition des anomalies</h2>
                    <div className="chart-container">
                        <Pie data={pieData} options={pieOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssemblyDashboardPage;
