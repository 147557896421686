import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cuttingTasks } from "../services/cuttingTasksData";

const CuttingTaskDetailsPage = () => {
    const { id } = useParams(); // Récupère l'ID de la tâche depuis l'URL
    const navigate = useNavigate();

    // Recherche de la tâche par ID
    const task = cuttingTasks.find((task) => task.id === parseInt(id));

    if (!task) {
        return <p className="text-center text-red-500">Tâche non trouvée.</p>;
    }

    const handleEdit = () => {
        navigate(`/edit-cutting-task/${id}`);
    };

    const handleDelete = () => {
        const confirmed = window.confirm(
            "Êtes-vous sûr de vouloir supprimer cette tâche ?"
        );
        if (confirmed) {
            console.log(`Tâche supprimée : ${task.name}`);
            navigate("/cutting-tasks");
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de la tâche de découpe</h1>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Informations générales</h2>
                <p><strong>Nom :</strong> {task.name}</p>
                <p><strong>Date de début :</strong> {task.startDate}</p>
                <p><strong>Opérateur :</strong> {task.operator}</p>
                <p><strong>Statut :</strong> {task.status}</p>
            </div>

            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold">Historique des modifications</h2>
                {/* Remplacez par des données réelles d'historique */}
                <ul className="list-disc ml-4">
                    <li>2024-11-20 : Création de la tâche par Jean Dupont</li>
                    <li>2024-11-22 : Modification du statut à "En cours"</li>
                </ul>
            </div>

            <div className="flex justify-between">
                <button
                    onClick={handleEdit}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Modifier
                </button>
                <button
                    onClick={handleDelete}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Supprimer
                </button>
            </div>
        </div>
    );
};

export default CuttingTaskDetailsPage;
