import React, { useState } from "react";
import { cuttingTasks } from "../services/cuttingTasksData";
import { materials } from "../services/../../Materials/services/materialsData";
import { cuttingMachines } from "../services/cuttingMachinesData";

const ResourceAllocation = () => {
    const [form, setForm] = useState({
        taskId: "",
        materialId: "",
        machineId: "",
        operator: "",
    });

    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des champs
        if (!form.taskId || !form.materialId || !form.machineId || !form.operator) {
            setError("Tous les champs sont obligatoires.");
            setSuccessMessage("");
            return;
        }

        // Simulez l'allocation des ressources (remplacez avec un appel API si nécessaire)
        console.log("Ressources allouées :", form);

        // Réinitialiser le formulaire et afficher un message de succès
        setForm({
            taskId: "",
            materialId: "",
            machineId: "",
            operator: "",
        });
        setError("");
        setSuccessMessage("Ressources allouées avec succès !");
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Allocation des ressources</h1>

            {/* Formulaire pour allouer les ressources */}
            <div className="bg-white shadow-md rounded p-4">
                <h2 className="text-lg font-bold mb-4">Formulaire d'allocation</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block mb-2 font-semibold">Tâche :</label>
                        <select
                            name="taskId"
                            value={form.taskId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez une tâche</option>
                            {cuttingTasks.map((task) => (
                                <option key={task.id} value={task.id}>
                                    {task.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block mb-2 font-semibold">Matière première :</label>
                        <select
                            name="materialId"
                            value={form.materialId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez une matière</option>
                            {materials.map((material) => (
                                <option key={material.id} value={material.id}>
                                    {material.name} ({material.quantity} {material.unit})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block mb-2 font-semibold">Machine :</label>
                        <select
                            name="machineId"
                            value={form.machineId}
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        >
                            <option value="">Sélectionnez une machine</option>
                            {cuttingMachines.map((machine) => (
                                <option key={machine.id} value={machine.id}>
                                    {machine.name} ({machine.status})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block mb-2 font-semibold">Opérateur :</label>
                        <input
                            type="text"
                            name="operator"
                            value={form.operator}
                            onChange={handleInputChange}
                            placeholder="Nom de l'opérateur"
                            className="border border-gray-300 rounded p-2 w-full"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                    >
                        Allouer les ressources
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResourceAllocation;
