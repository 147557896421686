// Page to add a new Materials item
import React, { useState } from "react";

const AddMaterial = () => {
    const [form, setForm] = useState({
        name: "",
        quantity: "",
        location: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Nouvelle matière ajoutée :", form);
        // Ajouter la logique pour sauvegarder les données (statique pour l'instant)
        setForm({ name: "", quantity: "", location: "" });
    };

    return (
        <div>
            <h1>Ajouter une matière première</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nom :</label>
                    <input
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Quantité :</label>
                    <input
                        type="number"
                        name="quantity"
                        value={form.quantity}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Localisation :</label>
                    <input
                        type="text"
                        name="location"
                        value={form.location}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Ajouter</button>
            </form>
        </div>
    );
};

export default AddMaterial;
