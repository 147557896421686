import React from "react";
import styles from "./AssemblyTable.module.css";

const AssemblyTable = ({ tasks }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Statut</th>
                <th>Opérateur</th>
                <th>Station</th>
            </tr>
        </thead>
        <tbody>
            {tasks.map((task) => (
                <tr key={task.id}>
                    <td>{task.id}</td>
                    <td>{task.description}</td>
                    <td>
                        <span
                            className={
                                task.status === "Terminée"
                                    ? styles.completed
                                    : task.status === "En cours"
                                        ? styles.inProgress
                                        : styles.pending
                            }
                        >
                            {task.status}
                        </span>
                    </td>
                    <td>{task.operator}</td>
                    <td>{task.station}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default AssemblyTable;
