import React, { useState } from "react";
import { packagedBoxesData } from "../services/packagingData";
import { Link } from "react-router-dom";

const PackagedBoxesTracking = () => {
    const [filter, setFilter] = useState("");
    const [data, setData] = useState(packagedBoxesData);

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredData = data.filter(
        (box) =>
            box.name.toLowerCase().includes(filter) ||
            box.status.toLowerCase().includes(filter)
    );

    const handleMarkAsReady = (id) => {
        setData(
            data.map((box) =>
                box.id === id ? { ...box, status: "Prête à expédier" } : box
            )
        );
        alert(`La boîte avec ID ${id} a été marquée comme prête à expédier.`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des boîtes emballées</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des boîtes emballées */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Contenu</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((box) => (
                            <tr key={box.id}>
                                <td className="border border-gray-300 p-2">{box.name}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${box.status === "Prête à expédier"
                                            ? "text-green-500"
                                            : "text-orange-500"
                                        }`}
                                >
                                    {box.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {box.contents.join(", ")}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {box.status !== "Prête à expédier" && (
                                        <button
                                            onClick={() => handleMarkAsReady(box.id)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                        >
                                            Marquer comme prête
                                        </button>
                                    )}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <Link
                                        to={`/packaged-box/${box.id}`}
                                        className="text-blue-500 hover:underline"
                                    >
                                        Voir les détails
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune boîte trouvée.</p>
            )}
        </div>
    );
};

export default PackagedBoxesTracking;
