import React, { useState, useEffect } from "react";
import { productionTasks } from "../services/productionTasksData";
import { machines } from "../services/machineData";
import { operators } from "../services/operatorData";

const RealTimeProduction = () => {
    const [tasks, setTasks] = useState(productionTasks);

    // Simulation de mise à jour en temps réel (Polling ou WebSocket)
    useEffect(() => {
        const interval = setInterval(() => {
            // Simulation de mises à jour des tâches
            const updatedTasks = tasks.map((task) => {
                if (task.status === "En cours") {
                    const randomProgress = Math.min(task.progress + Math.random() * 10, 100);
                    return {
                        ...task,
                        progress: randomProgress,
                        status: randomProgress === 100 ? "Terminée" : task.status,
                    };
                }
                return task;
            });
            setTasks(updatedTasks);
        }, 5000);

        return () => clearInterval(interval);
    }, [tasks]);

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi en temps réel de la production</h1>

            {tasks.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la tâche</th>
                            <th className="border border-gray-300 p-2">Étape</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Machine</th>
                            <th className="border border-gray-300 p-2">Progression</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map((task) => (
                            <tr key={task.id}>
                                <td className="border border-gray-300 p-2">{task.name}</td>
                                <td className="border border-gray-300 p-2">{task.step}</td>
                                <td className="border border-gray-300 p-2">{task.status}</td>
                                <td className="border border-gray-300 p-2">
                                    {operators.find((op) => op.id === task.operatorId)?.name || "Non assigné"}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {machines.find((machine) => machine.id === task.machineId)?.name || "Non assignée"}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <div className="w-full bg-gray-200 rounded-full h-4">
                                        <div
                                            className={`h-4 rounded-full ${task.progress === 100 ? "bg-green-500" : "bg-blue-500"
                                                }`}
                                            style={{ width: `${task.progress}%` }}
                                        ></div>
                                    </div>
                                    <span className="text-sm">{Math.round(task.progress)}%</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune tâche en cours.</p>
            )}
        </div>
    );
};

export default RealTimeProduction;
