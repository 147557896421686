import React, { useState } from "react";
import { shippingData } from "../services/shippingData";

const ShippingList = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const handleMarkAsShipped = (id) => {
        const confirmed = window.confirm("Êtes-vous sûr de vouloir marquer cette boîte comme expédiée ?");
        if (confirmed) {
            const updatedData = shippingData.map((item) =>
                item.id === id ? { ...item, status: "Expédiée" } : item
            );
            alert(`Boîte ${id} marquée comme expédiée.`);
        }
    };

    const handleEditShippingInfo = (id) => {
        alert(`Modification des informations d'expédition pour la boîte ${id}.`);
        // Ajouter la logique pour rediriger ou ouvrir une modal d'édition
    };

    const filteredData = shippingData.filter(
        (item) =>
            item.name.toLowerCase().includes(filter) ||
            item.status.toLowerCase().includes(filter) ||
            item.destination.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des expéditions</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, statut ou destination..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des expéditions */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Destination</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.name}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${item.status === "Expédiée" ? "text-green-500" : "text-orange-500"
                                        }`}
                                >
                                    {item.status}
                                </td>
                                <td className="border border-gray-300 p-2">{item.destination}</td>
                                <td className="border border-gray-300 p-2">
                                    {item.status !== "Expédiée" && (
                                        <button
                                            onClick={() => handleMarkAsShipped(item.id)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700 mr-2"
                                        >
                                            Marquer comme expédiée
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleEditShippingInfo(item.id)}
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700"
                                    >
                                        Modifier
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune donnée trouvée.</p>
            )}
        </div>
    );
};

export default ShippingList;
