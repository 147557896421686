import React, { useState } from "react";
import { movements } from "../services/movementsData";
import { materials } from "../services/materialsData";

const MovementHistory = () => {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const movementsPerPage = 5;

    // Filtrer les mouvements par le nom de la matière
    const filteredMovements = movements.filter((movement) =>
        materials
            .find((material) => material.id === movement.materialId)
            ?.name.toLowerCase()
            .includes(filter.toLowerCase())
    );

    // Pagination
    const totalPages = Math.ceil(filteredMovements.length / movementsPerPage);
    const displayedMovements = filteredMovements.slice(
        (currentPage - 1) * movementsPerPage,
        currentPage * movementsPerPage
    );

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1); // Réinitialise à la première page lors d'un changement de filtre
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (direction === "next" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Historique des mouvements</h1>

            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher une matière..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {displayedMovements.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Matière</th>
                            <th className="border border-gray-300 p-2">Type</th>
                            <th className="border border-gray-300 p-2">Quantité</th>
                            <th className="border border-gray-300 p-2">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedMovements.map((movement) => {
                            const material = materials.find(
                                (material) => material.id === movement.materialId
                            );
                            return (
                                <tr key={movement.id}>
                                    <td className="border border-gray-300 p-2">{movement.date}</td>
                                    <td className="border border-gray-300 p-2">{material?.name || "Inconnu"}</td>
                                    <td className="border border-gray-300 p-2">{movement.type}</td>
                                    <td className="border border-gray-300 p-2">{movement.quantity}</td>
                                    <td className="border border-gray-300 p-2">{movement.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun mouvement trouvé.</p>
            )}

            <div className="flex justify-between items-center mt-4">
                <button
                    onClick={() => handlePageChange("prev")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === 1}
                >
                    Précédent
                </button>
                <span>
                    Page {currentPage} sur {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange("next")}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                    disabled={currentPage === totalPages}
                >
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default MovementHistory;
