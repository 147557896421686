import React from "react";
import { useNavigate } from "react-router-dom";
import { assemblyTasks } from "../services/assemblyData";
import AssemblyTable from "../components/AssemblyTable";

const AssemblyList = () => {
    const navigate = useNavigate();

    const handleAssignAssembly = () => {
        navigate("/assign-assembly-task");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Tâches d'assemblage</h1>
            <AssemblyTable tasks={assemblyTasks} />
            <button
                onClick={handleAssignAssembly}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Assigner un assemblage
            </button>
        </div>
    );
};

export default AssemblyList;
