import React, { useState } from "react";
import { equipmentData } from "../services/equipmentData";
import { useNavigate } from "react-router-dom";

const EquipmentList = () => {
    const [filter, setFilter] = useState("");
    const [equipments, setEquipments] = useState(equipmentData);
    const navigate = useNavigate();

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const handleAddEquipment = () => {
        navigate("/add-equipment");
    };

    const handleEditEquipment = (id) => {
        navigate(`/edit-equipment/${id}`);
    };

    const handleMarkOutOfService = (id) => {
        const confirmed = window.confirm("Êtes-vous sûr de vouloir marquer ce matériel comme hors service ?");
        if (confirmed) {
            setEquipments((prevEquipments) =>
                prevEquipments.map((equipment) =>
                    equipment.id === id ? { ...equipment, status: "Hors service" } : equipment
                )
            );
        }
    };

    const filteredEquipments = equipments.filter(
        (equipment) =>
            equipment.name.toLowerCase().includes(filter) ||
            equipment.status.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des matériels</h1>

            {/* Barre de recherche */}
            <div className="mb-4 flex justify-between items-center">
                <input
                    type="text"
                    placeholder="Rechercher par nom ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-2/3"
                />
                <button
                    onClick={handleAddEquipment}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Ajouter un matériel
                </button>
            </div>

            {/* Tableau des matériels */}
            {filteredEquipments.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Dernière utilisation</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEquipments.map((equipment) => (
                            <tr key={equipment.id}>
                                <td className="border border-gray-300 p-2">{equipment.name}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${equipment.status === "En service"
                                            ? "text-green-500"
                                            : equipment.status === "Hors service"
                                                ? "text-red-500"
                                                : "text-orange-500"
                                        }`}
                                >
                                    {equipment.status}
                                </td>
                                <td className="border border-gray-300 p-2">{equipment.lastUsed}</td>
                                <td className="border border-gray-300 p-2">
                                    <button
                                        onClick={() => handleEditEquipment(equipment.id)}
                                        className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700 mr-2"
                                    >
                                        Modifier
                                    </button>
                                    {equipment.status !== "Hors service" && (
                                        <button
                                            onClick={() => handleMarkOutOfService(equipment.id)}
                                            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                        >
                                            Hors service
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucun matériel trouvé.</p>
            )}
        </div>
    );
};

export default EquipmentList;
