export const shippingData = [
    {
        id: 1,
        name: "Boîte A",
        status: "Prête pour expédition",
        destination: "Paris",
    },
    {
        id: 2,
        name: "Boîte B",
        status: "Expédiée",
        destination: "Lyon",
    },
    {
        id: 3,
        name: "Boîte C",
        status: "Prête pour expédition",
        destination: "Marseille",
    },
    {
        id: 4,
        name: "Boîte D",
        status: "Prête pour expédition",
        destination: "Toulouse",
    },
];

export const shippingTrackingData = [
    {
        id: 101,
        carrier: "DHL",
        destination: "Paris",
        status: "En cours",
        lastUpdated: "2024-11-21 10:00",
    },
    {
        id: 102,
        carrier: "UPS",
        destination: "Lyon",
        status: "Livrée",
        lastUpdated: "2024-11-20 15:30",
    },
    {
        id: 103,
        carrier: "FedEx",
        destination: "Marseille",
        status: "Retardée",
        lastUpdated: "2024-11-20 18:00",
    },
    {
        id: 104,
        carrier: "DHL",
        destination: "Toulouse",
        status: "Manquante",
        lastUpdated: "2024-11-19 14:00",
    },
];

export const shippingAnomaliesData = [
    {
        id: 1,
        description: "Boîte manquante pour l'expédition 101",
        status: "En attente",
    },
    {
        id: 2,
        description: "Boîte endommagée pendant le transport pour l'expédition 102",
        status: "En attente",
    },
];

export const shippingHistoryData = [
    {
        id: 101,
        carrier: "DHL",
        destination: "Paris",
        status: "Livrée",
        date: "2024-11-20",
    },
    {
        id: 102,
        carrier: "UPS",
        destination: "Lyon",
        status: "Livrée",
        date: "2024-11-19",
    },
    {
        id: 103,
        carrier: "FedEx",
        destination: "Marseille",
        status: "Retardée",
        date: "2024-11-18",
    },
    {
        id: 104,
        carrier: "DHL",
        destination: "Toulouse",
        status: "Manquante",
        date: "2024-11-17",
    },
];


