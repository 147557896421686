export const productionAnomalies = [
    {
        id: 1,
        description: "Erreur de découpe sur le tissu A",
        step: "Découpe",
        operator: "Jean Dupont",
        status: "Signalée",
        date: "2024-11-22",
    },
    {
        id: 2,
        description: "Assemblage incorrect des poches",
        step: "Assemblage",
        operator: "Marie Curie",
        status: "Résolue",
        date: "2024-11-21",
    },
];
