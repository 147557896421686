import React from "react";
import { useNavigate } from "react-router-dom";
import { materials } from "../services/materialsData";
import MaterialTable from "../components/MaterialTable";

const MaterialsList = () => {
    const navigate = useNavigate();

    const handleAddMaterial = () => {
        navigate("/add-material"); // Redirige vers la page d'ajout
    };

    const handleGoToCriticalThresholds = () => {
        navigate("/critical-thresholds");
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Matières premières</h1>
            <MaterialTable materials={materials} />
            <button
                onClick={handleAddMaterial}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
                Ajouter une matière
            </button>
            <button
                onClick={handleGoToCriticalThresholds}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
            >
                Voir les seuils critiques
            </button>
        </div>
    );
};

export default MaterialsList;
