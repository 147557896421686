import React, { useState } from "react";
import { correctionsHistoryData } from "../services/correctionsHistoryData";

const CorrectionsHistory = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredData = correctionsHistoryData.filter(
        (entry) =>
            entry.pieceName.toLowerCase().includes(filter) ||
            entry.operator.toLowerCase().includes(filter) ||
            entry.status.toLowerCase().includes(filter) ||
            entry.type.toLowerCase().includes(filter)
    );

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Historique des corrections et remplacements</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom de pièce, type, statut ou opérateur..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau de l'historique */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Date</th>
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Type</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((entry) => (
                            <tr key={entry.id}>
                                <td className="border border-gray-300 p-2">{entry.date}</td>
                                <td className="border border-gray-300 p-2">{entry.pieceName}</td>
                                <td className="border border-gray-300 p-2">{entry.type}</td>
                                <td className="border border-gray-300 p-2">{entry.operator}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${entry.status === "Validée"
                                            ? "text-green-500"
                                            : entry.status === "Rejetée à nouveau"
                                                ? "text-red-500"
                                                : "text-blue-500"
                                        }`}
                                >
                                    {entry.status}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune correction ou remplacement trouvé.</p>
            )}
        </div>
    );
};

export default CorrectionsHistory;
