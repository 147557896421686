export const qualityInspections = [
    {
        id: 1,
        piece: "Chemise",
        status: "En attente",
        inspector: "Alice Johnson",
        defects: "Aucun",
    },
    {
        id: 2,
        piece: "Pantalon",
        status: "En cours",
        inspector: "Bob Smith",
        defects: "Couture irrégulière",
    },
    {
        id: 3,
        piece: "Veste",
        status: "Validée",
        inspector: "Charlie Brown",
        defects: "Aucun",
    },
];
