import React, { useState, useEffect } from "react";
import { equipmentData } from "../services/equipmentData";

const RealTimeEquipment = () => {
    const [equipments, setEquipments] = useState(equipmentData);

    // Simuler des mises à jour en temps réel
    useEffect(() => {
        const interval = setInterval(() => {
            const updatedEquipments = equipments.map((equipment) => {
                // Simuler une panne ou une mise à jour aléatoire
                if (Math.random() > 0.9) {
                    return {
                        ...equipment,
                        status: "Panne détectée",
                    };
                }
                return equipment;
            });
            setEquipments(updatedEquipments);
        }, 5000); // Mettre à jour toutes les 5 secondes

        return () => clearInterval(interval);
    }, [equipments]);

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi en temps réel des matériels</h1>

            {/* Notifications */}
            <div className="mb-4">
                {equipments.some((equipment) => equipment.status === "Panne détectée") && (
                    <p className="text-red-500 font-semibold">
                        Attention : Une ou plusieurs pannes détectées !
                    </p>
                )}
            </div>

            {/* Tableau dynamique des matériels */}
            <table className="w-full border-collapse border border-gray-300">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Nom</th>
                        <th className="border border-gray-300 p-2">Statut</th>
                        <th className="border border-gray-300 p-2">Dernière utilisation</th>
                    </tr>
                </thead>
                <tbody>
                    {equipments.map((equipment) => (
                        <tr key={equipment.id}>
                            <td className="border border-gray-300 p-2">{equipment.name}</td>
                            <td
                                className={`border border-gray-300 p-2 ${equipment.status === "En service"
                                        ? "text-green-500"
                                        : equipment.status === "En maintenance"
                                            ? "text-orange-500"
                                            : "text-red-500"
                                    }`}
                            >
                                {equipment.status}
                            </td>
                            <td className="border border-gray-300 p-2">{equipment.lastUsed}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RealTimeEquipment;
