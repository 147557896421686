export const tasks = [
    {
        id: 1,
        description: "Découpe de tissus pour chemises",
        status: "En cours",
        operator: "John Doe",
        machine: "Découpeuse A",
    },
    {
        id: 2,
        description: "Découpe de tissus pour pantalons",
        status: "En attente",
        operator: "Jane Smith",
        machine: "Découpeuse B",
    },
    {
        id: 3,
        description: "Découpe de tissus pour vestes",
        status: "Terminée",
        operator: "Michael Brown",
        machine: "Découpeuse C",
    },
];
