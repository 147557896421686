export const productionHistory = [
    {
        id: 1,
        date: "2024-11-20",
        taskName: "Découpe tissu A",
        operator: "Jean Dupont",
        duration: "1h 30m",
    },
    {
        id: 2,
        date: "2024-11-18",
        taskName: "Assemblage poches",
        operator: "Marie Curie",
        duration: "2h 15m",
    },
    {
        id: 3,
        date: "2024-11-19",
        taskName: "Couture manches",
        operator: "Paul Martin",
        duration: "3h 00m",
    },
    {
        id: 4,
        date: "2024-11-21",
        taskName: "Assemblage cols",
        operator: "Alice Doe",
        duration: "2h 45m",
    },
    {
        id: 5,
        date: "2024-11-22",
        taskName: "Finition poches",
        operator: "John Smith",
        duration: "1h 10m",
    },
    {
        id: 6,
        date: "2024-11-23",
        taskName: "Assemblage complet",
        operator: "Paul Martin",
        duration: "4h 30m",
    },
];
