import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { validationData } from "../services/validationData";
import { defectData } from "../services/defectsData";

const ValidationDetails = () => {
    const { id } = useParams(); // Récupérer l'ID de la pièce depuis l'URL
    const navigate = useNavigate();

    const piece = validationData.find((item) => item.id === parseInt(id));

    if (!piece) {
        return <p className="text-center text-red-500">Pièce non trouvée.</p>;
    }

    const handleValidate = () => {
        alert(`La pièce "${piece.name}" a été validée.`);
        navigate("/internal-validations");
    };

    const handleReject = () => {
        navigate(`/report-defects/${id}`);
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Détails de la pièce</h1>

            {/* Informations générales */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Informations générales</h2>
                <p>
                    <strong>Nom :</strong> {piece.name}
                </p>
                <p>
                    <strong>Statut :</strong> {piece.status}
                </p>
                <p>
                    <strong>Opérateur :</strong> {piece.operator}
                </p>
            </div>

            {/* Historique des défauts */}
            <div className="bg-white shadow-md rounded p-4 mb-6">
                <h2 className="text-lg font-bold mb-2">Historique des défauts</h2>
                {defectData.filter((defect) => defect.pieceId === piece.id).length > 0 ? (
                    <ul>
                        {defectData
                            .filter((defect) => defect.pieceId === piece.id)
                            .map((defect) => (
                                <li key={defect.id} className="mb-2">
                                    <strong>Date :</strong> {defect.date} <br />
                                    <strong>Description :</strong> {defect.description}
                                </li>
                            ))}
                    </ul>
                ) : (
                    <p>Aucun défaut détecté pour cette pièce.</p>
                )}
            </div>

            {/* Actions */}
            <div className="flex justify-between">
                <button
                    onClick={handleValidate}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                >
                    Valider
                </button>
                <button
                    onClick={handleReject}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Rejeter
                </button>
                <button
                    onClick={() => navigate(`/report-defects/${piece.id}`)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                    Signaler un défaut
                </button>
            </div>
        </div>
    );
};

export default ValidationDetails;
