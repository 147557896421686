import React, { useState } from "react";
import { correctionsData } from "../services/correctionsData";

const CorrectionsTracking = () => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredCorrections = correctionsData.filter(
        (correction) =>
            correction.pieceName.toLowerCase().includes(filter) ||
            correction.status.toLowerCase().includes(filter) ||
            correction.operator.toLowerCase().includes(filter)
    );

    const handleMarkAsValidated = (id) => {
        alert(`La correction pour la pièce avec ID ${id} a été marquée comme validée.`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Suivi des corrections</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, opérateur ou statut..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
            </div>

            {/* Tableau des corrections */}
            {filteredCorrections.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Étape</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCorrections.map((correction) => (
                            <tr key={correction.id}>
                                <td className="border border-gray-300 p-2">{correction.pieceName}</td>
                                <td className="border border-gray-300 p-2">{correction.operator}</td>
                                <td className="border border-gray-300 p-2">{correction.correctionStep}</td>
                                <td
                                    className={`border border-gray-300 p-2 ${correction.status === "Corrigé"
                                            ? "text-green-500"
                                            : correction.status === "Rejeté à nouveau"
                                                ? "text-red-500"
                                                : "text-blue-500"
                                        }`}
                                >
                                    {correction.status}
                                </td>
                                <td className="border border-gray-300 p-2">
                                    {correction.status === "Corrigé" && (
                                        <button
                                            onClick={() => handleMarkAsValidated(correction.id)}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                        >
                                            Marquer comme validée
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune correction trouvée.</p>
            )}
        </div>
    );
};

export default CorrectionsTracking;
