import React from "react";
import styles from "./ExportTable.module.css";

const ExportTable = ({ exports }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Numéro de boîte</th>
                <th>Destination</th>
                <th>Statut</th>
                <th>Poids</th>
                <th>Exporté par</th>
            </tr>
        </thead>
        <tbody>
            {exports.map((exportItem) => (
                <tr key={exportItem.id}>
                    <td>{exportItem.id}</td>
                    <td>{exportItem.boxNumber}</td>
                    <td>{exportItem.destination}</td>
                    <td>
                        <span
                            className={
                                exportItem.status === "Terminée"
                                    ? styles.completed
                                    : exportItem.status === "En attente"
                                        ? styles.pending
                                        : styles.inProgress
                            }
                        >
                            {exportItem.status}
                        </span>
                    </td>
                    <td>{exportItem.weight}</td>
                    <td>{exportItem.exportedBy}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default ExportTable;
