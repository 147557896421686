import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validationData } from "../services/validationData";

const InternalValidationList = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState(validationData);

    const handleFilterChange = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);

        // Appliquer les filtres sur les données
        const filtered = validationData.filter(
            (item) =>
                item.name.toLowerCase().includes(filterValue) ||
                item.status.toLowerCase().includes(filterValue) ||
                item.operator.toLowerCase().includes(filterValue)
        );
        setFilteredData(filtered);
    };

    const handleValidate = (id) => {
        // Mettre à jour le statut de la pièce dans les données
        const updatedData = filteredData.map((item) =>
            item.id === id ? { ...item, status: "Validée" } : item
        );
        setFilteredData(updatedData);
        alert("Pièce validée !");
    };

    const handleReject = (id) => {
        // Rediriger vers une page pour signaler une anomalie
        navigate(`/validation-details/${id}`);
    };

    return (
        <div className="max-w-6xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">Gestion des validations internes</h1>

            {/* Barre de recherche */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom, statut ou opérateur..."
                    value={filter}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full"
                />
                <button
                    onClick={() => navigate("/batch-validation")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Validation par lot
                </button>
            </div>

            {/* Tableau des validations */}
            {filteredData.length > 0 ? (
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Nom de la pièce</th>
                            <th className="border border-gray-300 p-2">Statut</th>
                            <th className="border border-gray-300 p-2">Opérateur</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td className="border border-gray-300 p-2">{item.name}</td>
                                <td className="border border-gray-300 p-2">{item.status}</td>
                                <td className="border border-gray-300 p-2">{item.operator}</td>
                                <td className="border border-gray-300 p-2">
                                    {item.status !== "Validée" && (
                                        <button
                                            onClick={() => handleValidate(item.id)}
                                            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 mr-2"
                                        >
                                            Valider
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleReject(item.id)}
                                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        Rejeter
                                    </button>
                                    <button
                                        onClick={() => navigate(`/validation-details/${item.id}`)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        Voir détails
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">Aucune pièce trouvée.</p>
            )}
        </div>
    );
};

export default InternalValidationList;
