import React from "react";
import styles from "./MaterialTable.module.css";
import { Link } from "react-router-dom";

const MaterialTable = ({ materials }) => (
    <table className={styles.table}>
        <thead>
            <tr>
                <th>ID</th>
                <th>Nom</th>
                <th>Quantité</th>
                <th>Localisation</th>
            </tr>
        </thead>
        <tbody>
            {materials.map((material) => (
                <tr key={material.id}>
                    <td>{material.id}</td>
                    <td>{material.name}</td>
                    <td>{material.quantity}</td>
                    <td>{material.location}</td>
                    <td>
                        <Link to={`/material/${material.id}`} className="text-blue-500 underline">
                            Voir détails
                        </Link>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

export default MaterialTable;
